const initialState = {
    loading: true,
    search_query: ''
};

export default function appReduces(state = initialState, action) {
    switch (action.type) {
        case 'SET_APP_LOADING': {
            return ({
                ...state,
                loading: action.status
            });
        }
        case 'SET_SEARCH_QUERY': {
            console.log(action.text)
            return ({
                ...state,
                search_query: action.text
            });
        }
        default: {
            return state;
        }
    }
}