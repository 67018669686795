import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _ from "lodash";
import { AiOutlinePhone, AiOutlineShop } from 'react-icons/ai'
import { IoEarth, IoLocationOutline, IoShapesOutline } from 'react-icons/io5'
import { BiCalendarHeart } from 'react-icons/bi'
import { _fetch } from '../../netReq';
import { InfoView } from '../../resources/infoView';
import { CircularProgress, Paper } from '@material-ui/core';

export default function Info() {

    const info = useSelector(state => state.userReducer)
    const [loading, setLoading] = useState(true)
    const search = useLocation().search;
    const [oldResult, setOldResult] = useState()
    const [updateResult, setUpdateResult] = useState()
    const name = new URLSearchParams(search).get('name');

    function creatKeys(dat) {
        return {
            locationName: {
                dat: dat.locationName,
                info: dat.locationName,
                name: 'Name',
                Icon: AiOutlineShop
            },
            primaryCategory: {
                path: 'displayName',
                name: 'Category',
                info: dat.primaryCategory.displayName,
                dat: dat.primaryCategory,
                Icon: IoShapesOutline
            },
            address: {
                dat: dat.address,
                name: 'Address',
                info: dat.address,
                Icon: IoLocationOutline
            },
            regularHours: {
                path: 'periods',
                dat: dat.regularHours,
                info: dat.regularHours.periods,
                name: 'Hours',
                Icon: IoLocationOutline
            },
            primaryPhone: {
                dat: dat.primaryPhone,
                info: dat.primaryPhone,
                name: 'Phone',
                Icon: AiOutlinePhone
            },
            websiteUrl: {
                dat: dat.websiteUrl,
                info: dat.websiteUrl,
                name: 'Website',
                Icon: IoEarth
            },
            openInfo: {
                path: 'openingDate',
                info: dat.openInfo.openingDate,
                dat: dat.openInfo,
                name: 'Opening Date',
                Icon: BiCalendarHeart
            }
        }
    }

    const handleComponent = (selected) => {
        _fetch(`${process.env.REACT_APP_GMB_URL}${name}?updateMask=${selected.nm}`, {
            method: 'PATCH', token: info.accessToken,
            body:
            {
                [selected.nm]: selected.dat
            }
        })
            .then(result => {
                setOldResult(creatKeys(result))
                _fetch(`${process.env.REACT_APP_GMB_URL}${name}:googleUpdated`, { method: 'GET', token: info.accessToken })
                    .then(
                        (result) => {
                            setUpdateResult(creatKeys(result.location))
                        },
                        (error) => {
                            process.env.NODE_ENV === 'development' && console.log(error)
                        }
                    )
                    .catch(error => {
                        process.env.NODE_ENV === 'development' && console.log(error)
                    })
            })
    }

    useEffect(() => {
        if (info.accessToken)
            _fetch(`${process.env.REACT_APP_GMB_URL}${name}:googleUpdated`, { token: info.accessToken })
                .then(
                    (result) => {
                        setUpdateResult(creatKeys(result.location))
                        return _fetch(`${process.env.REACT_APP_GMB_URL}${name}`, { token: info.accessToken })
                            .then(result1 => {
                                setOldResult(creatKeys(result1))
                                setLoading(false)
                            })
                    },
                    (error) => {
                        process.env.NODE_ENV === 'development' && console.log(error)
                    }
                )
                .catch(error => {
                    process.env.NODE_ENV === 'development' && console.log(error)
                })
    }, [info.accessToken, name]);

    return (
        loading
            ?
            <CircularProgress size={75} className='m-auto' />
            :
            <Paper className='px-2 overflow-auto' elevation={0}>
                {
                    Object.keys(oldResult).map((key, i) => (
                        <InfoView key={i} mask={key} old={oldResult[key]}
                            update={!_.isEqual(oldResult[key]?.info, updateResult[key]?.info) ? updateResult[key] : null}
                            handleComponent={handleComponent}
                        />
                    ))
                }
            </Paper>
    )
}