import React, { useCallback, useEffect, useState } from 'react';
import ReviewView from './reviewView'
import { useSelector } from 'react-redux';
import {
	Typography, List, ListItemText, ListItem, Divider,
	Button, Paper, ListItemIcon, CircularProgress, Checkbox, IconButton, Grid, Chip
} from '@material-ui/core';
import { _fetch } from '../../netReq';
import LocationFilter from '../../resources/locationFilter'
import { Link, useHistory } from 'react-router-dom';
import { blue } from '@material-ui/core/colors'
import Scrollbars from 'react-custom-scrollbars';
import LineIconRegular from '../../react-lineicons-regular'
import LineIconLight from '../../react-lineicons-light'


function giveDateTime(givenDTFormat) {
	return new Date(givenDTFormat).toLocaleDateString()
}

const locationStateMap = (location, update) => {
	if (!update) return '';
	let locName = location.locationName;
	let res = location.locationState[update.split('.')[1]];
	let obj = res ?
		{
			"locationState.isGoogleUpdated": 'You have updates on google maps for this listing.',
			"locationState.isDuplicate": `${locName} has been marked duplicate by Google.`,
			"locationState.isSuspended": `Google has suspended ${locName}.`,
			"locationState.canUpdate": '',
			"locationState.canDelete": '',
			"locationState.isVerified": `${locName} has been verified by Google.`,
			"locationState.needsReverification": `${locName} needs to be reverified with Google.`,
			"locationState.isPendingReview": `${locName} is under review by Google.`,
			"locationState.isDisabled": `Google has disabled ${locName}.`,
			"locationState.isPublished": `${locName} is now live on Google.`,
			"locationState.isDisconnected": '',
			"locationState.isLocalPostApiDisabled": `Google has disabled local posts on ${locName}.`,
			"locationState.canModifyServiceList": '',
			"locationState.canHaveFoodMenus": `You can now put food menus on the ${locName}.`,
			"locationState.hasPendingEdits": `${locName} have suggested edits pending for your review.`,
			"locationState.hasPendingVerification": `Verification of ${locName} with google is pending.`,
			"locationState.canOperateHealthData": `You can now operate health data on the ${locName}.`,
			"locationState.canOperateLodgingData": `You can now operate lodging data on the ${locName}.`
		}
		:
		{
			"locationState.isGoogleUpdated": '',
			"locationState.isDuplicate": `${locName} has been marked original by Google.`,
			"locationState.isSuspended": `Google has taken back suspension of ${locName}.`,
			"locationState.canUpdate": '',
			"locationState.canDelete": '',
			"locationState.isVerified": `Google has removed verified status from ${locName}.`,
			"locationState.needsReverification": '',
			"locationState.isPendingReview": '',
			"locationState.isDisabled": `Google has enabled ${locName}.`,
			"locationState.isPublished": `${locName} is no longer live on Google.`,
			"locationState.isDisconnected": '',
			"locationState.isLocalPostApiDisabled": `Google has enabled local posts on ${locName}.`,
			"locationState.canModifyServiceList": '',
			"locationState.canHaveFoodMenus": `You are not allowed to put food menus on the ${locName}.`,
			"locationState.hasPendingEdits": '',
			"locationState.hasPendingVerification": '',
			"locationState.canOperateHealthData": `You are not allowed to operate health data on the ${locName}.`,
			"locationState.canOperateLodgingData": `You are not allowed to operate lodging data on the ${locName}.`
		};
	return obj[update];
}

export default function Notifications() {

	const [loading, setLoading] = useState(true);
	const [loader2, setLoader2] = useState(false);
	const [review, setReview] = useState({});
	const [noti, setNoti] = useState([]);
	const [clicked, setClicked] = useState('');
	const [clickDelete, setClickDelete] = useState(false);
	const [totalPages, setTotalePages] = useState(0);
	const [currPage, setCurrPage] = useState(0);
	const [selectedLocations, setSelectedLocations] = useState([]);
	const [selected, setSelected] = useState([]);

	const user = useSelector(state => state.userReducer);
	const history = useHistory();
	const accessToken = user.accessToken;

    useEffect(() => {
        if (user.locations && Array.isArray(user.locations) && user.locations.length > 0) {
            setSelectedLocations(user.locations);
        }
    }, [user.locations]);
	
	const fetchNotification = useCallback(() => {
		if (Array.isArray(selectedLocations) && selectedLocations.length > 0) {

			let path = `${process.env.REACT_APP_API_URL}/notifications?limit=10`

			if (currPage > 0)
				path += `&page=${currPage}`;

			if(!Array.isArray(selectedLocations) || !selectedLocations.length)
                return setNoti([]);
			
			path += `&locationIds=${JSON.stringify(selectedLocations.map((e) => e.locationId))}`;
			
			setLoading(true);

			_fetch(path)
				.then((result) => {
					if (result.status === 200) {
						if (currPage === 0) {
							setNoti(Array.isArray(result.response.result) ? result.response.result.map(e => ({ ...e, index: user.locations.findIndex(l => e.locationId === l.locationId) })) : []);
							setTotalePages(result.response.total);
						} else {
							let res = result.response?.result;
							setNoti(val => ([
								...val,
								...Array.isArray(res) ? res.map(e => ({ ...e, index: user.locations.findIndex(l => e.locationId === l.locationId) })) : []])
							);
							setTotalePages(result.response.total);
						}
					}
				})
				.catch(error => {
					process.env.NODE_ENV === 'development' && console.log(error);
				})
				.finally(() => {
					setLoading(false);
				})
		}
	}, [currPage, selectedLocations, user]);

	useEffect(() => {
		fetchNotification();
	}, [fetchNotification]);

	function getReview(name, id) {
		_fetch(`${process.env.REACT_APP_GMB_URL}${name}`, { token: accessToken })
			.then(
				(result) => {
					setReview(result);
					setClicked(id);
					setLoader2(false);
				},
				(error) => {
					process.env.NODE_ENV === 'development' && console.log(error);
				}
			)
			.catch(error => {
				process.env.NODE_ENV === 'development' && console.log(error);
			})
	}

	function deleteNoti() {
		setClickDelete(false);
		setLoader2(true);
		_fetch(`${process.env.REACT_APP_API_URL}/notifications?id=${JSON.stringify(selected)}`, { method: 'DELETE' })
			.then((result) => {
				process.env.NODE_ENV === 'development' && console.log(result);
				if (result.success) {
					fetchNotification();
				}
			})
			.catch(error => {
				process.env.NODE_ENV === 'development' && console.log(error)
			})
			.finally(() => {
				setLoader2(false);
			})
	}

	return (
		<Paper className='d-flex flex-column p-3' elevation={0}>

			<div className="d-flex px-3 align-items-center">
				<LocationFilter
					setSelectedLocations={setSelectedLocations}
					selectedLocs={selectedLocations}
				/>
				{
					clickDelete
						?
						<div>
							<Typography className="text-center">
								Are you sure?
							</Typography>
							<div>
								<Button
									className="mr-1"
									color='secondary'
									variant='outlined'
									size='small'
									onClick={() => deleteNoti()}
								>
									Yes
								</Button>
								<Button
									variant='outlined'
									color='secondary'
									size='small'
									onClick={() => setClickDelete(false)}
								>
									No
								</Button>
							</div>
						</div>
						:
						loader2 ?
							<CircularProgress size={20} />
							:
							selected.length
								?
								<IconButton onClick={() => setClickDelete()} color='primary'>
									<LineIconLight name='trash-can-alt-2'/>
								</IconButton>
								:
								null
				}
			</div>

			<div className='px-4 flex-fill d-flex mt-4'>
				<Scrollbars
					autoHide style={{ height: 500 }}
					onScrollFrame={(val) => {
						if (val.top >= 1 && currPage < totalPages - 1) {
							setCurrPage(currPage + 1)
						}
					}}
				>
					{
						loading || user.statsLoading
							?
							<CircularProgress size={75} className='m-auto' />
							:
							noti.length > 0
								?
								<React.Fragment>

									<List className='w-100' dense>

										{
											noti.map((e, i) => {

												let message = '', color = '', chip = '', last = i === (noti.length - 1);
												console.log(last);

												switch (e.type) {
													case 'NEW_REVIEW':
														color = '#6e5acb';
														chip = 'REVIEW';
														message = 'You have got a new review';
														break;
													case 'UPDATED_REVIEW':
														chip = 'REVIEW';
														color = '#6e5acb';
														message = 'Someone has updated their review';
														break;
													case 'NEW_QUESTION':
														chip = 'QUESTION';
														color = '#11998e';
														message = 'You have got a new question';
														break;
													case 'UPDATED_QUESTION':
														chip = 'QUESTION';
														color = '#11998e';
														message = 'Someone has updated their question';
														break;
													case 'NEW_ANSWER':
														chip = 'ANSWER';
														color = '#38ef7d';
														message = 'You have got a new answer on a question';
														break;
													case 'UPDATED_ANSWER':
														chip = 'ANSWER';
														color = '#38ef7d';
														message = 'Someone has updated their answer on a question';
														break;
													case 'NEW_CUSTOMER_MEDIA':
														chip = 'MEDIA';
														color = '#d553ab';
														message = 'Someone has posted media on google maps';
														break;
													case 'UPDATED_LOCATION_STATE':
														chip = 'UPDATE';
														color = '#e0814d';
														message = locationStateMap(user.locations[e.index], e.locationStateMask);
														break;
													case 'GOOGLE_UPDATE':
														chip = 'SUGGESTION';
														color = '#e0814d';
														message = 'Listing has updates from Google.';
														break;
													default:
														message = '';
														break;
												}

												if (!message) return null;

												return (
													<React.Fragment key={e._id}>

														<Divider />

														<ListItem
															onClick={() => {
																if (clicked === e._id)
																	setClicked('');
																else if (e.type === 'NEW_REVIEW' || e.type === 'UPDATED_REVIEW') {
																	setLoader2(e._id)
																	getReview(e.reviewName, e._id)
																}
																else if (e.type === 'NEW_QUESTION' || e.type === 'UPDATED_QUESTION' || e.type === 'NEW_ANSWER' || e.type === 'UPDATED_ANSWER') {
																	setLoader2(e._id)
																	history.push(`questions?id=${e.locationId}`)
																}
																else if (e.type === 'GOOGLE_UPDATE') {
																	setClicked(e._id);
																}
																if (!e.read)
																	_fetch(`${process.env.REACT_APP_API_URL}/notifications/read?id=${e._id}`)
																		.then(res => process.env.NODE_ENV === 'development' && console.log(res))
																		.catch(err => process.env.NODE_ENV === 'development' && console.log(err));
															}}
															button={true}
															selected={!e.read}
														>
															<ListItemIcon>
																<Checkbox
																	edge="start"
																	checked={selected.indexOf(e._id) !== -1}
																	disableRipple
																	onChange={(ev) => {
																		if (ev.target.checked) {
																			setSelected([...selected, e._id])
																		} else {
																			setSelected(selected.filter(s => e._id !== s))
																		}
																	}}
																/>
															</ListItemIcon>
															<ListItemText
																primary={
																	<Grid container spacing={1} alignItems='center'>
																		<Grid item xs={4} style={{ fontWeight: e.read ? 500 : 600 }}>
																			{user.locations[e.index].locationName} {user.locations[e.index].nickName ? `(${user.locations[e.index].nickName})` : ''}
																		</Grid>
																		<Grid item xs={2}>
																			<Chip
																				style={{ backgroundColor: color, color: '#fff', fontWeight: 500 }}
																				label={chip}
																			/>
																		</Grid>
																		<Grid item xs={4} style={{ fontWeight: e.read ? 500 : 600 }}>
																			{message}
																		</Grid>
																		<Grid item xs={2} style={{ fontWeight: e.read ? 500 : 600 }}>
																			{giveDateTime(e.created_at)}
																		</Grid>
																	</Grid>
																}
															/>
														</ListItem>

														{
															clicked === e._id && (e.type === 'NEW_REVIEW' || e.type === 'UPDATED_REVIEW')
																?
																<ReviewView
																	name={review.name}
																	comment={review.comment}
																	updateTime={review.updateTime}
																	starRating={review.starRating}
																	displayName={review.reviewer?.displayName}
																	profilePic={review.reviewer?.profilePhotoUrl}
																	replyComment={review.reviewReply?.comment}
																	replyTime={review.reviewReply?.updateTime}
																/>
																:
																null
														}

														{
															clicked === e._id && (e.type === 'GOOGLE_UPDATE')
																?
																<ListItem onClick={() => history.push(`/info?name=${e.locationName}`)}>
																	<ListItemIcon className="align-self-start py-1">
																	</ListItemIcon>
																	<ListItemText
																		primary={`Updated fields: ${e.updateMask}`}
																		secondary={<Link to={`/info?name=${e.locationName}`}>Update Now <LineIconRegular name = 'corner-up-right' style={{color:blue['A700']}} size='sm'/></Link>}
																	/>
																</ListItem>
																:
																null
														}

														<Divider />
														
													</React.Fragment>
												);
											})
										}
									</List>
								</React.Fragment>
								:
								<Typography variant='h5' className='m-auto'>
									No new notifications
								</Typography>

					}
				</Scrollbars>
			</div>
		</Paper >
	)
}