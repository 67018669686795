import { Card, CardHeader, Paper, Button, CardContent, List, ListItem, ListItemIcon, ListItemText, Grid } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { primaryColor } from '../../theme';
import LineIconLight from '../../react-lineicons-light';

export default function Pricing() {

    let history = useHistory();

    const features = [
        "1 Google listing",
        "Health Score",
        "Hijacking Protection",
        "Instant Notifications via Email & SMS",
        "Review Management",
        "Auto Reply of Reviews",
        "Q & A Management",
        "Post Scheduling",
        "Manage multiple GMB listings from one account",
    ];

    return (
        <Paper className='flex-fill p-3 mb-3'>
            <Grid container justify='center'>
                <Grid item>
                    <Card variant='outlined'>
                        <CardHeader
                            title={<h5 className='text-white-50'>JEEMB PRO</h5>}
                            subheader={<h2 className='text-white'>₹250/- per month</h2>}
                            style={{ backgroundColor: primaryColor }}
                        />
                        <CardContent>
                            <List className='mb-3' disablePadding dense>
                                {
                                    features.map((e, i) => (
                                        <ListItem key={i}>
                                            <ListItemIcon>
                                                <LineIconLight name='checkmark' className='mr-2 text-success' size='sm' />
                                            </ListItemIcon>
                                            <ListItemText primary={e} />
                                        </ListItem>
                                    ))
                                }
                            </List>
                            <div className="text-center">
                                <Button color='primary' variant='contained' size='large' onClick={() => history.push('/register')}>
                                    START FREE TRAIL
                                </Button>
                            </div>
                            <h6 className='text-center pt-4'>
                                14-day free trial and no credit card required.
                            </h6>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Paper >
    );
}