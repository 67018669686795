import { createTheme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

export const theme = createTheme({
	palette: {
		background: {
			default: '#F7F6F5'
		},
		primary: {
			main: '#6e5acb',
			light: '#917df0'
		},
		secondary: {
			main: green[600]
		}
	},
	typography: {
		fontFamily: [
			'Montserrat',
			'sans-serif'
		],
		body1: {
			fontSize: '16px'
		},
		body2: {
			fontSize: '14px'
		},
		fontSize: 14,
		h6: {
			fontSize: '16px'
		}
	},
	overrides: {
		MuiButton: {
			root: {
				fontSize: 14,
				borderTopLeftRadius: 30,
				borderTopRightRadius: 30,
				borderBottomLeftRadius: 30,
				borderBottomRightRadius: 30,
				minWidth: 100,
				textTransform: 'capitalize'
			},
		},
		MuiInputBase: {
			input: {
				fontSize: 14
			}
		},
		MuiFormLabel: {
			root: {
				fontSize: 14
			}
		},
		MuiPaper: {
			root: {
				color: '#1d2136e5'
			}
		},
		MuiAppBar: {
			root: {
				boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 7%), 0px 1px 10px 0px rgb(0 0 0 / 10%)'
			}
		}
	},
});

export const primaryColor = '#6e5acb';