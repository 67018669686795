import { IconButton, Typography, Collapse } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import LineIconLight from "../../react-lineicons-light";

export default function PasswordPolicy({ show }) {

    const [_show, setShow] = useState(false);

    useEffect(() => {
        setShow(show)
    }, [show]);


    return (
        <React.Fragment>

            <Typography variant='caption' onClick={() => setShow(!_show)} className='pointer'>
                Password Policy
                <IconButton className='pl-1' size='small'>
                    {
                        !_show
                            ?
                            <LineIconLight name='chevron-down-circle'  size='sm'/>
                            :
                            <LineIconLight name='chevron-up-circle' size='sm'/>
                    }
                </IconButton>
            </Typography>

            <Collapse in={_show} unmountOnExit>
                <ul>
                    <li><Typography variant='caption'>At least 8 character long</Typography></li>
                    <li><Typography variant='caption'>At least 1 uppercase character (A-Z)</Typography></li>
                    <li><Typography variant='caption'>At least 1 lowercase character (a-z)</Typography></li>
                    <li><Typography variant='caption'>At least 1 numeric character (0-9)</Typography></li>
                    <li><Typography variant='caption'>At least 1 symbol (- # ! $ @ % ^ &amp; * ( ) _ + | ~ = ` {'{ }'} [ ] : " ; ' {'< >'} ? , . /)</Typography></li>
                </ul>
            </Collapse>
        </React.Fragment>
    )
}