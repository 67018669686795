import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { Alert } from "@material-ui/lab";
import MuiThemeProvider from '@material-ui/styles/ThemeProvider';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { theme } from './theme';
import './App.css';

import Scheduler from './components/scheduler';
import PrivacyPolicy from './components/privacyPolicy';
import Terms from './components/Terms';
import Home from './components/home';
import AccountLogin from './components/account/sigin';
import Notifications from './components/notifications';
import Reviews from './components/review';
import Questions from './components/questions';
import Info from './components/info';
import ScheduledPost from './components/scheduled_post';
import Registration from './components/account/register';
import Verified from './components/verified';
import Login from './components/login';
import BuySlots from './components/buySlots';
import Profile from './components/profile';
import ResetPassword from './components/account/resetPass';
import ForgotPassword from './components/account/forgotPass';
import ManageListings from './components/manageListings';
import Error from './components/error';
import LandingPage from './components/landingPage';
import Pricing from './components/pricing';
import RefundAndCancellation from './components/cancellationAndRefund';
import About from './components/about';
import Footer from './components/footer';

import Header from './resources/header';
import JeeMBFeedback from './resources/feedback';

import { setLoading } from './redux/action/appActions';
import { loadGoogleAccounts, initAccount } from './redux/action/action';
import BillingManagement from './components/billingManagement';
import KnowledgeBase from './components/HelpArticles';
import AddMobileNumber from './components/HelpArticles/addMobileNumber';
import AddNickname from './components/HelpArticles/addNickname';
import OpenCloseLocation from './components/HelpArticles/openCloseLocation';
import AutoReject from './components/HelpArticles/autoReject';
import AutoReviewReply from './components/HelpArticles/autoReviewReply';
import SearchVideos from './components/searchVideos';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const ShortLink = () => {
	let { code } = useParams();
	window.location = `${process.env.REACT_APP_API_URL}/s?code=${code}`;
	return (null);
}

const ComponentWrapper = ({ Component, ...props }) => {

	const red = useSelector(st => st.userReducer);

	return (
		<Box className="h-100 d-flex flex-column">
			<Header />
			<div className='container app-container d-flex flex-column align-items-stretch'
				style={{ marginTop: 60, paddingTop: 10 }}
			>

				{
					red.account?.subscription_status === 'FREE_TRIAL' && red.account?.trial_left >= 0 &&
					<Alert
						severity={red.account?.trial_left > 6 ? 'info' : 'warning'}
						variant='filled' onClose={() => null} style={{ marginBottom: 10 }}
					>
						<Typography
							variant='button'
							color='white'
							style={{ fontWeight: 700 }}
						>
							{
								red.account?.trial_left === 0 ?
									'Your free trial has expired'
									:
									`Your trial period has ${red.account?.trial_left} days remaining`
							}
						</Typography>
					</Alert>
				}
				<Component {...props} />
			</div>
		</Box>
	);
};

const ArticleWrapper = ({ Component, ...props }) => {
	return (
		<React.Fragment>
			<Header />
			<div className='bg-white w-100' style={{ marginTop: 60, paddingTop: 10, minHeight: '100%' }}>
				<div className='container py-5'>
					<Component {...props} />
				</div>
			</div>
		</React.Fragment>
	);
};

function App() {
	const [cookies, setCookie, removeCookie] = useCookies(['account_id']);
	const dispatch = useDispatch();
	const red = useSelector(st => st.userReducer);
	const _loading = useSelector(st => st.appReducer.loading);
	const mixpanel = useMixpanel();
	const [loading_google_account, setLoadingGoogleAccount] = useState(false);

	// this function will load the local account initially in the app
	async function init() {
		try {
			dispatch(setLoading(true));
			let result = await fetch(`${process.env.REACT_APP_API_URL}/account`, { credentials: 'include' });
			if (result.status === 200) {
				result = await result.json();
				dispatch(initAccount(result.response));
				mixpanel.identify(result.response?.account?.id);
				mixpanel.people.set({
					email: result.response?.account?.email,
					name: result.response?.account?.name
				});
				let users = result.response.users;
				if (Array.isArray(users) && users.length > 0) {
					let account_id = cookies.account_id;
					let current_user = users.find(e => e.account_id === account_id);
					if (!current_user) setCookie('account_id', users[0].account_id, { maxAge: 30 * 24 * 60 * 60 });
				} else if (cookies.account_id) {
					removeCookie('account_id');
				}
			} else {
				dispatch(initAccount({}));
				removeCookie('account_id');
				dispatch(setLoading(false));
				return;
			}
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(setLoading(false));
		}
	}

	// this function will load google account only if the value in cookie changes and exists
	const loadGoogleAccount = useCallback(async () => {
		if (cookies.account_id && Array.isArray(red.users) && red.users.length > 0 && red.account_id !== cookies.account_id && !loading_google_account) {
			setLoadingGoogleAccount(true);
			let currentUser = red.users.find(e => e.account_id === cookies.account_id);
			await loadGoogleAccounts(dispatch, cookies.account_id, currentUser);
			setLoadingGoogleAccount(false);
		}
	}, [cookies.account_id, red.users, red.account_id, loading_google_account, dispatch])

	useEffect(() => {
		loadGoogleAccount()
	}, [loadGoogleAccount])

	useEffect(() => {
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const authenticated = red.account?.email;

	return (
		<Elements stripe={stripePromise}>
			<MuiThemeProvider theme={theme}>
				<CssBaseline />
				<Suspense fallback={<CircularProgress size={75} className='m-auto' />}>
					<Router>
						<Switch>
							<Route path='/home' key='land' exact>
								<LandingPage />
							</Route>
							<Route path='/s/:code' exact key='sl'>
								<ComponentWrapper Component={ShortLink} />
							</Route>
							<Route path='/verified' key='verified' exact>
								<ComponentWrapper Component={Verified} />
							</Route>
							<Route path='/privacy' exact key='privacy'>
								<ComponentWrapper Component={PrivacyPolicy} />
							</Route>
							<Route path='/terms' exact key='privacy'>
								<ComponentWrapper Component={Terms} />
							</Route>
							<Route path='/error' key='error'>
								<ComponentWrapper Component={Error} />
							</Route>
							<Route path='/knowledgebase' exact key='knowledgebase'>
								<ArticleWrapper Component={KnowledgeBase} />
							</Route>
							<Route path='/knowledgebase/add-mobile-number' exact key='add-mobile-number'>
								<ArticleWrapper Component={AddMobileNumber} />
							</Route>
							<Route path='/knowledgebase/add-nickname' exact key='add-mobile-number'>
								<ArticleWrapper Component={AddNickname} />
							</Route>
							<Route path='/knowledgebase/open-close-location' exact key='add-mobile-number'>
								<ArticleWrapper Component={OpenCloseLocation} />
							</Route>
							<Route path='/knowledgebase/auto-reject' exact key='add-mobile-number'>
								<ArticleWrapper Component={AutoReject} />
							</Route>
							<Route path='/knowledgebase/review-management' exact key='add-mobile-number'>
								<ArticleWrapper Component={AutoReviewReply} />
							</Route>
							<Route path='/pricing' exact key='privacy'>
								<ComponentWrapper Component={Pricing} />
							</Route>
							<Route path='/refund_and_cancellation' exact key='privacy'>
								<ComponentWrapper Component={RefundAndCancellation} />
							</Route>
							<Route path='/about' key='register' exact>
								<ComponentWrapper Component={About} />
							</Route>
							<Route path='/search' key='search' exact>
								<ComponentWrapper Component={SearchVideos} />
							</Route>

							{
								!authenticated ?
									<Switch>
										<Route path={['/', '']} key='land' exact>
											<Header />
											<LandingPage />
										</Route>
										<Route path='/register' key='register' exact>
											<ComponentWrapper Component={Registration} />
										</Route>
										<Route path='/forgot_pass' key='password_reset' exact>
											<ComponentWrapper Component={ForgotPassword} />
										</Route>
										<Route path='/reset_pass' key='password_reset' exact>
											<ComponentWrapper Component={ResetPassword} />
										</Route>
										<Route path='*' key='localAccount'>
											<ComponentWrapper Component={AccountLogin} />
										</Route>
									</Switch>
									:
									_loading || loading_google_account ?
										<Switch>
											<Route path='*'>
												<CircularProgress size={75} className='m-auto' />
											</Route>
										</Switch>
										:
										<Switch>
											<Route path='/buy_slots' key='buySlots' exact>
												<ComponentWrapper Component={BuySlots} />
											</Route>
											<Route path='/billing' key='billing' exact>
												<ComponentWrapper Component={BillingManagement} />
											</Route>
											<Route path='/login' key='login' exact>
												<ComponentWrapper Component={Login} />
											</Route>
											<Route path='/' key='homein' exact>
												{
													Array.isArray(red.locations) && red.locations.length > 0
														?
														<ComponentWrapper Component={Home} />
														:
														<ComponentWrapper Component={Login} />
												}
											</Route>
											<Route path='/post_scheduler' key='postSch' exact>
												<ComponentWrapper Component={Scheduler} />
											</Route>
											<Route path='/info' key='info'>
												<ComponentWrapper Component={Info} />
											</Route>
											<Route path='/reviews' key='reviews' exact>
												<ComponentWrapper Component={Reviews} />
											</Route>
											<Route path='/notifications' key='notifications' exact>
												<ComponentWrapper Component={Notifications} />
											</Route>
											<Route path='/questions' key='questions'>
												<ComponentWrapper Component={Questions} />
											</Route>
											<Route path='/scheduled' key='scheduled'>
												<ComponentWrapper Component={ScheduledPost} />
											</Route>
											<Route path='/profile' key='profile'>
												<ComponentWrapper Component={Profile} />
											</Route>
											<Route path='/manage_listings' key='manage_listings'>
												<ComponentWrapper Component={ManageListings} />
											</Route>
											<Route path='*' key='homein'>
												<ComponentWrapper Component={Error} />
											</Route>
										</Switch>
							}

							<Route path='*' key='_error'>
								<ComponentWrapper Component={Error} />
							</Route>

						</Switch>

						{
							!authenticated &&
							<Footer />
						}

						{
							authenticated ?
								<JeeMBFeedback />
								: null
						}

					</Router >
				</Suspense>
			</MuiThemeProvider >
		</Elements >
	);
}

export default App;
