import { Button, Card, CardContent, CardHeader, CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";

import { _fetch } from "../../netReq";
import { cardLay, buttonLay } from "./const";

export default function ForgotPassword() {

    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    function handleSubmit(ev) {
        ev.preventDefault();

        setError('');
        setSuccess(false);
        if (!email) return setError('Please enter a valid email address');
        setLoading(true);
        _fetch(`${process.env.REACT_APP_API_URL}/account/forgot_password?email=${email}`)
            .then(
                (result) => {
                    if (result.success) {
                        setSuccess(true);
                    }
                    else {
                        setError(result.response);
                    }
                },
                (error) => {
                    process.env.NODE_ENV === 'development' && console.log(error);
                    setError('Some error occurred. Please check you internet connectivity.');
                }
            )
            .catch(err => {
                setError('Some error occurred. Please check you internet connectivity.');
            })
            .finally(()=>setLoading(false));

        return false;
    }

    return (
        <Grid container justify="center" alignItems="center" className='h-100'>
            <Grid item xs={12} sm={8} md={6} className='my-auto'>
                <Card className={cardLay} elevation={0}>
                    <CardHeader title={<Typography variant='h4'> Reset Password </Typography>} />
                    <CardContent>
                        <form className='d-flex flex-column' onSubmit={handleSubmit}>
                            <TextField
                                label='Email'
                                required
                                className='my-3'
                                variant='outlined'
                                type='email'
                                name='email'
                                value={email}
                                placeholder='Enter Email Id'
                                onChange={e => {
                                    setEmail(e.target.value);
                                }}
                            />
                            {
                                success &&
                                <Typography variant='body2' color='secondary' className='my-1'>
                                    Password reset mail sent successfully. Please check your email id to reset your password.
                                </Typography>
                            }
                            {
                                error
                                    ?
                                    <Typography variant='body2' color='error' className='my-1'>
                                        {error}
                                    </Typography>
                                    :
                                    null
                            }
                            <Button className={buttonLay} variant='contained' color='primary' type='submit' >
                                {loading && <CircularProgress size={25} className='mr-1'/>} Reset Password
                        </Button>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}