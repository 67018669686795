import React from 'react';
import { Hidden, Typography } from '@material-ui/core';

import './styles.css';

export default function AddMobileNumber() {

    return (
        <div className='d-flex flex-column article-container h-100'>

            <h3 className='mb-3 article-heading'>
                How to Add Mobile Number in JeeMB
            </h3>

            <Typography variant='subtitle2' className='mb-4'>
                Last updated: September 23, 2021
            </Typography>

            <Hidden smUp>
                <iframe
                    className='mx-auto' width="320" height="180" src='https://www.youtube.com/embed/TpeAb72SmHA'
                    title="YouTube video player" frameborder="0" allowFullScreen style={{minHeight: 169 }}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
            </Hidden>
            <Hidden only={['xs', 'md', 'lg', 'xl']}>
                <iframe
                    className='mx-auto' width="560" height="315" src='https://www.youtube.com/embed/TpeAb72SmHA'
                    title="YouTube video player" frameborder="0" allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
            </Hidden>
            <Hidden only={['xs', 'sm']}>
                <iframe
                    className='mx-auto' width="700" height="405" src='https://www.youtube.com/embed/TpeAb72SmHA'
                    title="YouTube video player" frameborder="0" allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
            </Hidden>

            <p className='article-content'>
                Adding Mobile Number facility is available in JeeMB software. It is easy to add a mobile number to a JeeMB account. In this section, learn how to add your mobile number to a JeeMB account.
            </p>

            <ol>
                <li className='article-content'>
                    First and foremost, log in to your JeeMB account by entering your correct credentials. The first time you log in will prompt you to the following screen.
                    <img src={`${process.env.REACT_APP_AWS_BUCKET}img/click-here-button.png`} alt='...' className='article-image img img-fluid' />
                </li>
                <li className='article-content'>
                    From here, click on the ‘Click Here’ button visible on the blue shaded section.
                </li>
                <li className='article-content'>
                    In the following section, enter your basic information like Name, E-mail address, and Phone Number. Please make sure to enter the correct details.
                    <img src={`${process.env.REACT_APP_AWS_BUCKET}img/prompt+screen.png`} alt='...' className='article-image img img-fluid' />
                </li>
                <li className='article-content'>
                    After entering your phone number, click on the ‘Verify’ button.
                    <img src={`${process.env.REACT_APP_AWS_BUCKET}img/verify.png`} alt='...' className='article-image img img-fluid' />
                </li>
                <li className='article-content'>
                    You will receive an OTP in your mobile number, type the same code in the Verification Code box and click on ‘Verify’.
                    <img src={`${process.env.REACT_APP_AWS_BUCKET}img/verification+code.png`} alt='...' className='article-image img img-fluid' />
                </li>
                <li className='article-content'>To get instant alerts at your account.</li>
                <li className='article-content'>From your JeeMB account, you can set the alerts for ‘Review’, ‘Question’, ‘Google Update’.</li>
            </ol>

        </div>
    );

}