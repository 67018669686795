import React from 'react';
import { Hidden, Typography } from '@material-ui/core';

import './styles.css';

export default function AutoReject() {

    return (
        <div className='d-flex flex-column article-container'>
            <h3 className='mb-3 article-heading'>
                What is Auto Reject Edit Suggestion Setting?
            </h3>
            <Typography variant='subtitle2' className='mb-4'>
                Last updated: September 23, 2021
            </Typography>


            <Hidden smUp>
                <iframe
                    className='mx-auto' width="320" height="180" src='https://www.youtube.com/embed/RioSohg2RIw'
                    title="YouTube video player" frameborder="0" allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
            </Hidden>
            <Hidden only={['xs', 'md', 'lg', 'xl']}>
                <iframe
                    className='mx-auto' width="560" height="315" src='https://www.youtube.com/embed/RioSohg2RIw'
                    title="YouTube video player" frameborder="0" allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
            </Hidden>
            <Hidden only={['xs', 'sm']}>
                <iframe
                    className='mx-auto' width="720" height="405" src='https://www.youtube.com/embed/RioSohg2RIw'
                    title="YouTube video player" frameborder="0" allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
            </Hidden>

            <p className='article-content'>
                Google and Map users can provide you with remarks, comments, and suggestions that can hamper the image of your Google Business Listing. Google does not provide any function of automatically rejecting suggestions. But with the help of JeeMB, you can automatically reject suggestions without doing much manual work. Following is the step-by-step guide to auto-reject edit suggestion setting using JeeMB.
            </p>

            <ol>
                <li className='article-content'>
                    Login to your JeeMB account. After successful sign-in, the JeeMB dashboard will appear on your screen. Browse for the ‘Auto-reject-edit suggestions’ tab located at the mid-screen of the dashboard.
                    <img src={`${process.env.REACT_APP_AWS_BUCKET}img/auto-edit-suggestion-1.png`} alt='...' className='article-image img img-fluid' />
                </li>
                <li className='article-content'>
                    To enable the service, all you need to do is slide on the tab. Once this feature gets enabled, whatever suggestions, editions that come from Google or map users, our JeeMB system will automatically reject them.
                    <img src={`${process.env.REACT_APP_AWS_BUCKET}img/auto-reject-edit-suggestion-2.png`} alt='...' className='article-image img img-fluid' />
                </li>
            </ol>

            <p className='article-content'>
                This way, Google listing will be safe from the curb of unwanted suggestions that comes in line with your Business Listing. These suggestions will not be approved. Your business listing is free from the intrusion of Edits!
            </p>

        </div>
    );

}