import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import './styles.css';
import LineIconLight from '../../react-lineicons-light';

export default function KnowledgeBase() {

    const linkClass = 'kb-link text-decoration-none d-flex flex-row';

    return (
        <React.Fragment>
            <h3 className='mb-5 article-heading'>
                Knowledge Base
            </h3>
            <Grid container >
                <Grid item xs={12} md={6} className='p-3'>
                    <h5 className='mb-4 article-subheading'>
                        How to ...
                    </h5>
                    <Typography variant='h6' className='mb-3'>
                        <Link className={linkClass} to='/knowledgebase/add-mobile-number'>
                            <LineIconLight name='arrow-right' />
                            <span className='ml-3'>Add Mobile Number in JeeMB</span>
                        </Link>
                    </Typography>
                    <Typography variant='h6' className='mb-3'>
                        <Link className={linkClass} to='/knowledgebase/add-nickname'>
                            <LineIconLight name='arrow-right' />
                            <span className='ml-3'>Add Nickname in your JeeMB listing</span>
                        </Link>
                    </Typography>
                    <Typography variant='h6' className='mb-3'>
                        <Link className={linkClass} to='/knowledgebase/open-close-location'>
                            <LineIconLight name='arrow-right' />
                            <span className='ml-3'>Open or Close Location your Listing from JeeMB</span>
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} className='p-3'>
                    <h5 className='mb-4 article-subheading'>
                        What is ...
                    </h5>
                    <Typography variant='h6' className='mb-3'>
                        <Link className={linkClass} to='/knowledgebase/auto-reject'>
                            <LineIconLight name='arrow-right' />
                            <span className='ml-3'>Auto Reject Edit Suggestion Setting</span>
                        </Link>
                    </Typography>
                    <Typography variant='h6' className='mb-3'>
                        <Link className={linkClass} to='/knowledgebase/review-management'>
                            <LineIconLight name='arrow-right' />
                            <span className='ml-3'>Review Tab and its short link and QR code</span>
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    );

}