import React, { useEffect, useState } from "react";
import {
    Paper, Button, Card, CardContent, Checkbox, Dialog, DialogContent,
    DialogTitle, TextField, Typography, CircularProgress, Grid
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { _fetch } from "../../netReq";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { toast } from "react-toastify";
import { updateAccount } from "../../redux/action/action";

export default function Profile() {

    let user_info = useSelector(st => st.userReducer);

    const [password, setPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [current_password, setCurrentPassword] = useState('');
    const [error, setError] = useState('');
    const [passwordSuccess, setPasswordSuccess] = useState(false);
    const [phone, setPhone] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [otp, setOtp] = useState();
    const [otpLoader, setOtpLoader] = useState(false);
    const [alertError, setAlertError] = useState('');
    const [allAlerts, setAllAlers] = useState({
        reviewEmail: false,
        reviewSms: false,
        questionEmail: false,
        questionSms: false,
        googleUpdateEmail: false,
        googleUpdateSms: false
    });

    const dispatch = useDispatch();

    useEffect(() => {
        _fetch(`${process.env.REACT_APP_API_URL}/account/alert`)
            .then(r => {
                process.env.NODE_ENV === 'development' && console.log(r.response.$init)
                if (r.success)
                    setAllAlers(r.response)
            }).catch(err => {
                process.env.NODE_ENV === 'development' && console.log(err)
            })
    }, [])

    useEffect(() => {
        setPhone(user_info.account?.phone)
    }, [user_info])

    const handleAlertChange = (event) => {
        let name = event.target.name;
        process.env.NODE_ENV === 'development' && console.log(event.target);
        if ((name === 'reviewSms' || name === 'questionSms' || name === 'googleUpdateSms') && !user_info.account?.phone)
            setAlertError('Add a Phone No. to get alerts via SMS');
        else {
            setAllAlers({ ...allAlerts, [event.target.name]: event.target.checked });
            setAlertError('');
        }
    }

    function handleAlertSave() {
        _fetch(`${process.env.REACT_APP_API_URL}/account/alert`, {
            method: 'PATCH',
            body: allAlerts
        })
            .then(r => {
                if (r.success)
                    toast.info('Saved!');
            }).catch(err => {
                process.env.NODE_ENV === 'development' && console.log(err)
                toast.error('Something went wrong');
            })
    }

    function handlePasswordSubmit(ev) {
        ev.preventDefault();

        setError('');
        setPasswordSuccess(false);
        if (confirm_password !== password) return setError('password and confirm password do not match');
        _fetch(`${process.env.REACT_APP_API_URL}/account/password`, {
            method: 'PATCH',
            body: { current_password, password }
        })
            .then(
                (result) => {
                    if (result.success) {
                        setPasswordSuccess(true);
                    }
                    else {
                        setError(result.response);
                    }
                },
                (error) => {
                    process.env.NODE_ENV === 'development' && console.log(error);
                    setError('Some error occurred. Please check you internet connectivity.');
                }
            )
            .catch(err => {
                setError('Some error occurred. Please check you internet connectivity.');
            });

        return false;
    }

    function handleOtp() {
        let regex = /([0|+[0-9]{1,5})?[ ]{0,1}([7-9][0-9]{9})/gm;
        if (!regex.test(phone)) return toast.error('Invalid Phone Number.')
        setOtpLoader(true);
        process.env.NODE_ENV === 'development' && console.log(allAlerts);
        _fetch(`${process.env.REACT_APP_API_URL}/account/send_otp?phone=${phone}`)
            .then((result) => {
                process.env.NODE_ENV === 'development' && console.log(result)
                if (result.success) {
                    process.env.NODE_ENV === 'development' && console.log('open dialog')
                    setOpenDialog(true);
                }
                else
                    toast.error(result.response);
            }).catch(err => {
                process.env.NODE_ENV === 'development' && console.log(err)
                toast.error(err);
            })
        setOtpLoader(false);
    }

    function handleVerifyOtp() {
        setOtpLoader(true);
        _fetch(`${process.env.REACT_APP_API_URL}/account/verify_otp?otp=${otp}`)
            .then(result => {
                if (result.success) {
                    process.env.NODE_ENV === 'development' && console.log(user_info)
                    dispatch(updateAccount({
                        ...user_info.account,
                        phone: result.response.phone
                    }))
                    process.env.NODE_ENV === 'development' && console.log(user_info)
                    setAllAlers({
                        ...allAlerts,
                        reviewSms: true,
                        questionSms: true,
                        googleUpdateSms: true
                    })
                    setOpenDialog(false);
                }
                else
                    toast.error(result.response)
            }).catch(err => {
                process.env.NODE_ENV === 'development' && console.log(err)
                toast.error(err);
            })
        setOtpLoader(false);
    }

    return (
        <Paper className='min-h-100 p-1 p-md-3 d-flex overflow-auto'>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    
                    {/* Basic info */}
                    <Card variant='outlined' className='mb-2'>
                        <Typography variant='h5' className='py-2 px-3'>Basic Info</Typography>
                        <CardContent>
                            <h6>Name</h6>
                            <p>{user_info.account?.name}</p>

                            <h6>Email Address</h6>
                            <p>{user_info.account.email}</p>
                            <Grid container >
                                <Grid item xs={12} md={10} className='d-flex flex-column flex-md-row justify-content-start'>
                                    <PhoneInput
                                        country={'in'}
                                        onlyCountries={['in']}
                                        preserveOrder={['onlyCountries', 'preferredCountries']}
                                        value={phone}
                                        countryCodeEditable={false}
                                        disableDropdown
                                        onChange={setPhone}
                                        inputClass='w-100'
                                        containerClass='w-100'
                                    />
                                    {
                                        phone !== user_info.account?.phone
                                            ?
                                            <Button className='ml-2' color='primary' onClick={handleOtp}>Verify</Button>
                                            :
                                            null
                                    }
                                </Grid>
                            </Grid>
                            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                                <DialogTitle>
                                    Enter the verification code
                            </DialogTitle>
                                <DialogContent className='d-flex flex-column mx-4 mb-2 mt-3'>
                                    <TextField
                                        lable='Code'
                                        color='primary'
                                        variant='outlined'
                                        placeholder='Enter Code'
                                        onChange={(e) => setOtp(e.target.value)}
                                    />
                                    <Button variant='contained' color='primary' className='w-50 mx-auto my-2'
                                        onClick={handleVerifyOtp}>
                                        {
                                            otpLoader ?
                                                <CircularProgress size={20} />
                                                :
                                                'Verify'
                                        }
                                    </Button>
                                    <Typography variant='caption' className='mx-auto mt-2 pointer' onClick={handleOtp}>
                                        Resend
                                    </Typography>
                                </DialogContent>
                            </Dialog>
                        </CardContent>
                    </Card>

                    {/* Alerts */}
                    <Card variant='outlined'>
                        <Typography variant='h5' className='py-2 px-3'>Alerts</Typography>

                        <CardContent>
                            <Grid container>
                                <Grid container item xs={12} sm={4} alignItems='center'>
                                    <Grid item xs={4} sm={12}>
                                        <Typography variant='subtitle2'>Review</Typography>
                                    </Grid>
                                    <Grid xs={4} sm={12} item className='d-flex align-items-center'>
                                        <Checkbox
                                            color='primary'
                                            checked={allAlerts.reviewEmail}
                                            onChange={handleAlertChange}
                                            name='reviewEmail'
                                        />
                                        Email
                                    </Grid>
                                    <Grid xs={4} sm={12} item className='d-flex align-items-center'>
                                        <Checkbox
                                            color='primary'
                                            checked={allAlerts.reviewSms}
                                            onChange={handleAlertChange}
                                            name='reviewSms'
                                        />
                                        SMS
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} sm={4} alignItems='center'>
                                    <Grid item xs={4} sm={12}>
                                        <Typography variant='subtitle2'>Question</Typography>
                                    </Grid>
                                    <Grid xs={4} sm={12} item className='d-flex align-items-center'>
                                        <Checkbox
                                            color='primary'
                                            checked={allAlerts.questionEmail}
                                            onChange={handleAlertChange}
                                            name='questionEmail'
                                        />
                                        Email
                                    </Grid>
                                    <Grid xs={4} sm={12} item className='d-flex align-items-center'>
                                        <Checkbox
                                            color='primary'
                                            checked={allAlerts.questionSms}
                                            onChange={handleAlertChange}
                                            name='questionSms'
                                        />
                                        SMS
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} sm={4} alignItems='center'>
                                    <Grid item xs={4} sm={12}>
                                        <Typography variant='subtitle2'>Google Update</Typography>
                                    </Grid>
                                    <Grid xs={4} sm={12} item className='d-flex align-items-center'>
                                        <Checkbox
                                            color='primary'
                                            checked={allAlerts.googleUpdateEmail}
                                            onChange={handleAlertChange}
                                            name='googleUpdateEmail'
                                        />
                                        Email
                                    </Grid>
                                    <Grid xs={4} sm={12} item className='d-flex align-items-center'>
                                        <Checkbox
                                            color='primary'
                                            checked={allAlerts.googleUpdateSms}
                                            onChange={handleAlertChange}
                                            name='googleUpdateSms'
                                        />
                                        SMS
                                    </Grid>
                                </Grid>
                            </Grid>
                            <div className='d-flex'>
                                <Button variant='contained' color='primary'
                                    onClick={handleAlertSave}>
                                    Save
                            </Button>
                                <Typography className='my-auto ml-4' color='error' variant='subtitle2'>
                                    {alertError}</Typography>
                            </div>
                        </CardContent>
                    </Card>

                </Grid>

                {/* Password management */}
                <Grid item xs={12} md={6}>
                    <Card variant='outlined'>
                        <Typography variant='h5' className='pt-2 pb-1 px-3'>Change Password</Typography>
                        <CardContent>
                            <form className='d-flex flex-column' onSubmit={handlePasswordSubmit}>
                                <TextField
                                    label='Current Password'
                                    required
                                    className='mb-2'
                                    variant='outlined'
                                    type='password'
                                    name='current_password'
                                    value={current_password}
                                    placeholder='Enter Current Password'
                                    onChange={e => {
                                        setCurrentPassword(e.target.value);
                                    }}
                                />
                                <TextField
                                    label='Password'
                                    required
                                    className='mb-2'
                                    variant='outlined'
                                    type='password'
                                    name='confirm_password'
                                    value={password}
                                    placeholder='Enter Password'
                                    onChange={e => {
                                        setPassword(e.target.value);
                                    }}
                                />
                                <TextField
                                    label='Confirm Password'
                                    required
                                    className='mb-2'
                                    variant='outlined'
                                    type='password'
                                    name='password'
                                    value={confirm_password}
                                    placeholder='Confirm Password'
                                    onChange={e => {
                                        setConfirmPassword(e.target.value);
                                    }}
                                />
                                {
                                    passwordSuccess &&
                                    <Typography variant='body2' className='my-2' color='secondary'>
                                        Password updated successfully.
                            </Typography>
                                }
                                {
                                    error
                                        ?
                                        <Typography variant='body2' color='error' className='my-2'>
                                            {error}
                                        </Typography>
                                        :
                                        null
                                }
                                <div>
                                    <Button variant='contained' color='primary' type='submit' >
                                        Change Password
                            </Button>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
        </Paper >
    );
}