import React, { useState } from "react";
import { List, ListItem, Divider, Paper, Typography, Slider, Grid } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { HashLink } from 'react-router-hash-link';
import { useHistory } from "react-router-dom";

import LineIconLight from '../../react-lineicons-light';
import { primaryColor } from '../../theme';
import './styles.css';
import Header from "../../resources/header";

export default function LandingPage() {

    const [picture, setPicture] = useState(`${process.env.REACT_APP_AWS_BUCKET}img/review-macro.png`);
    const [text, setText] = useState('Review Macro');
    const [selected, setSelected] = useState(1);

    let history = useHistory();

    return (
        <React.Fragment>

            <Header />

            <div style={{ marginTop: 60, paddingBottom: 40, paddingTop: 150 }} className='text-center d-flex flex-column bg-white'>

                <h1 className='landing-page-title text-center'>
                    <span style={{ borderBottom: `3px ${primaryColor} solid` }}>AUTOMATE</span> THE MANAGEMENT OF<br />
                    YOUR GOOGLE MY BUSINESS LISTINGS
                </h1>

                <div>
                    <button
                        style={{ background: 'linear-gradient(45deg, #6e5acb 30%, #d553ab 90%)' }}
                        className='text-white mx-auto mt-3 mb-2 rounded border-0 px-5 py-2 trial-button'
                        onClick={() => history.push('/register')}
                    >
                        <h3 className='mb-0'>START 14 DAYS FREE TRIAL</h3>
                    </button>

                    <h5 className='text-center mb-5 mt-2' style={{ fontWeight: 400 }}>no credit card required*</h5>

                    <div className='text-center'>
                        <img src={`${process.env.REACT_APP_AWS_BUCKET}img/listing-card.png`} alt='listing card' className='img img-fluid' />
                    </div>
                </div>

                <div style={{ marginTop: 80, marginBottom: 80 }}>
                    <div style={{ fontSize: 20, fontWeight: 500, lineHeight: '30px', wordSpacing: 5, marginBottom: 30 }}>
                        JeeMB is a Google My Business (GMB) listing management tool that is built for business owners<br />
                        and equipped with features to make local SEOs envy.
                    </div>

                    <HashLink to='/#features'>
                        <button
                            style={{ background: 'linear-gradient(45deg, #6e5acb 30%, #d553ab 90%)' }}
                            className='text-white mx-auto mt-3 mb-2 rounded border-0 px-5 py-2 trial-button'
                            type='button'
                        >
                            <h3 className='mb-0'>VIEW FEATURES</h3>
                        </button>
                    </HashLink>
                </div>

            </div>

            <div style={{ padding: '120px 0', backgroundColor: '#f0f0f0' }} id='features'>
                <div className='container my-2'>
                    <h3 className='text-center mb-5'>Complete Management Suite</h3>
                    <div className='row'>
                        <div className='col-3 d-flex'>
                            <List className='my-auto w-100'>
                                <Divider />
                                <ListItem
                                    selected={text === 'Review Macro'} button
                                    onMouseOver={() => {
                                        setPicture(`${process.env.REACT_APP_AWS_BUCKET}img/review-macro.png`);
                                        setText('Review Macro')
                                    }}
                                    className='py-3'
                                >
                                    Review Macro
                                </ListItem>
                                <Divider />
                                <ListItem
                                    selected={text === 'Reviews'} button
                                    onMouseOver={() => {
                                        setPicture(`${process.env.REACT_APP_AWS_BUCKET}img/reviews.png`);
                                        setText('Reviews')
                                    }}
                                    className='py-3'
                                >
                                    Reviews
                                </ListItem>
                                <Divider />
                                <ListItem
                                    selected={text === 'Questions'} button
                                    onMouseOver={() => {
                                        setPicture(`${process.env.REACT_APP_AWS_BUCKET}img/questions.png`);
                                        setText('Questions')
                                    }}
                                    className='py-3'
                                >
                                    Questions
                                </ListItem>
                                <Divider />
                                <ListItem
                                    selected={text === 'Post Scheduler'} button
                                    onMouseOver={() => {
                                        setPicture(`${process.env.REACT_APP_AWS_BUCKET}img/post-scheduler.png`);
                                        setText('Post Scheduler')
                                    }}
                                    className='py-3'
                                >
                                    Post Scheduler
                                </ListItem>
                                <Divider />
                                <ListItem
                                    selected={text === 'Combine View'} button
                                    onMouseOver={() => {
                                        setPicture(`${process.env.REACT_APP_AWS_BUCKET}img/calendar.png`);
                                        setText('Combine View')
                                    }}
                                    className='py-3'
                                >
                                    Combine View
                                </ListItem>
                                <Divider />
                            </List>
                        </div>
                        <div className='col-8 offset-1 d-flex justify-content-center'>
                            <Paper elevation={3} className='text-center p-3'>
                                <img src={picture} alt={text} className='img img-fluid' />
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: '#fff', padding: '140px 0px 200px 0' }} id='faq'>
                <div className='container'>
                    <h3 className='text-center mb-4'>Frequently Asked Questions</h3>

                    <Accordion style={{ backgroundColor: '#f0f0f0' }}>
                        <AccordionSummary
                            expandIcon={<LineIconLight name='chevron-down' size='sm' />}
                        >
                            <Typography variant='body2' style={{ fontWeight: 500 }}>
                                Is it safe?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2'>
                                You don’t have to add any manager/owner to use JeeMB. You will link your Google listings using your own account so it is perfectly safe to use JeeMB to manage your Google listings.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ backgroundColor: '#f0f0f0' }}>
                        <AccordionSummary
                            expandIcon={<LineIconLight name='chevron-down' size='sm' />}
                        >
                            <Typography variant='body2' style={{ fontWeight: 500 }}>
                                What is the health score?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2'>
                                Health score is an indicator of your listing’s health based on the most important factors of a Google my business listing. We evaluate each listing and give it a score to encourage you to follow best practices and keep your listing safe. It is not an official number by Google.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ backgroundColor: '#f0f0f0' }}>
                        <AccordionSummary
                            expandIcon={<LineIconLight name='chevron-down' size='sm' />}
                        >
                            <Typography variant='body2' style={{ fontWeight: 500 }}>
                                Can I add listings from multiple Google accounts in one account?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2'>
                                Yes, you can use more than one Google account/Gmail IDs to link listings in one JeeMB account.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ backgroundColor: '#f0f0f0' }}>
                        <AccordionSummary
                            expandIcon={<LineIconLight name='chevron-down' size='sm' />}
                        >
                            <Typography variant='body2' style={{ fontWeight: 500 }}>
                                Does it support chain business listings?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2'>
                                Unfortunately, we do not support chain business listings at this time. Please check back after sometime.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ backgroundColor: '#f0f0f0' }}>
                        <AccordionSummary
                            expandIcon={<LineIconLight name='chevron-down' size='sm' />}
                        >
                            <Typography variant='body2' style={{ fontWeight: 500 }}>
                                Can I customise the automatic replies to the reviews?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2'>
                                Yes, you can set different replies to different star rating value. In fact, you can set up more than one custom reply to one star rating value and JeeMB will randomly pick from your custom replies to post for that star rating value.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ backgroundColor: '#f0f0f0' }}>
                        <AccordionSummary
                            expandIcon={<LineIconLight name='chevron-down' size='sm' />}
                        >
                            <Typography variant='body2' style={{ fontWeight: 500 }}>
                                Do you provide training for the management of listing with JeeMB.
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2'>
                                You caught us early! We are working on help material and tutorial videos. Stay tuned!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ backgroundColor: '#f0f0f0' }}>
                        <AccordionSummary
                            expandIcon={<LineIconLight name='chevron-down' size='sm' />}
                        >
                            <Typography variant='body2' style={{ fontWeight: 500 }}>
                                Why does Google account permission window says “permission to delete listing”?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2'>
                                Its a standard Google permission dialogue box that states all scenarios associated with a certain permission. JeeMB doesn’t not have any provision built in to delete any listing.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                </div>
            </div>

            <div style={{ backgroundColor: '#f0f0f0', padding: '120px 0px 200px 0' }} className='text-center' id='pricing'>
                <div className='container'>
                    <h3 className='text-center mb-4'>Try Full Version of JeeMB for 14 days Without Any Attachment</h3>

                    <button
                        style={{ background: 'linear-gradient(45deg, #6e5acb 30%, #d553ab 90%)' }}
                        className='text-white mx-auto mt-3 mb-2 rounded border-0 px-5 py-2 trial-button'
                        onClick={() => history.push('/register')}
                    >
                        <h3 className='mb-0'>START 14 DAYS FREE TRIAL</h3>
                    </button>

                    <div style={{ marginTop: 100 }}>
                        <h3 className='text-center' style={{ marginBottom: 50 }}>JeeMB Pricing</h3>
                        <Slider
                            defaultValue={1}
                            step={1}
                            marks
                            valueLabelDisplay="on"
                            min={1}
                            max={10}
                            onChange={(ev, val) => setSelected(val)}
                            aria-labelledby="select-slots"
                        />
                        <Typography variant='h6' className='mt-5'>
                            <Divider className='my-3' />
                            <Grid container>
                                <Grid item xs={8}>
                                    JeeMB slot price/listing
                                </Grid>
                                <Grid item xs={4}>
                                    ₹{250}
                                </Grid>
                            </Grid>
                            <Divider className='my-3' />
                            <Grid container>
                                <Grid item xs={8}>Monthly Subscription Total</Grid>
                                <Grid item xs={4}>₹{250 * selected}</Grid>
                            </Grid>
                            <Divider className='my-3' />
                        </Typography>
                    </div>

                </div>
            </div>

        </React.Fragment>
    )
}