import {
    Button, Card, CardContent, CardHeader, CircularProgress, FormControl, Grid, IconButton, InputAdornment,
    InputLabel, OutlinedInput, Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import { _fetch } from "../../netReq";
import { cardLay, buttonLay } from "./const";
import validator from "validator";
import PasswordPolicy from "./PasswordPolicy";
import LineIconLight from "../../react-lineicons-light";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function ResetPassword() {

    const [password, setPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showPasswordPolicy, setShowPasswordPolicy] = useState(false);

    let query = useQuery();
    let history = useHistory();

    useEffect(() => {
        if (!query.get('tok')) history.push('/');
    })

    function handleSubmit(ev) {
        ev.preventDefault();

        setError('');
        
        if (!validator.isStrongPassword(password)) {
            setError('Password does not follow password policy');
            return setShowPasswordPolicy(true);
        }
        if (confirm_password !== password) return setError('password and confirm password do not match');
        
        setLoading(true);

        _fetch(`${process.env.REACT_APP_API_URL}/account/forgot_password`, {
            method: 'PATCH',
            body: {
                token: query.get('tok'),
                password: password
            }
        })
            .then(
                (result) => {
                    if (result.success) {
                        setSuccess(true);
                    }
                    else {
                        setError(result.response);
                    }
                },
                (error) => {
                    process.env.NODE_ENV === 'development' && console.log(error);
                    setError('Some error occurred. Please check you internet connectivity.');
                }
            )
            .catch(err => {
                setError('Some error occurred. Please check you internet connectivity.');
            })
            .finally(() => {
                setLoading(false);
            });

        return false;
    }

    return (
        <Grid container justify="center" alignItems="center" className='h-100'>
            <Grid item xs={12} sm={8} md={6} className='my-auto'>
                <Card className={cardLay} elevation={0}>
                    <CardHeader title={<Typography variant='h4'> Reset Password </Typography>} />
                    <CardContent>
                        <form className='d-flex flex-column' onSubmit={handleSubmit}>
                            <FormControl variant='outlined' className='my-3' required={true}>
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <OutlinedInput
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    label='Password* '
                                    placeholder='Enter Password'
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(showPassword ? false : true)}
                                            >
                                                {showPassword ? <LineIconLight name='unlock' size='md' /> : <LineIconLight name='lock' size='md' />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <FormControl variant='outlined' className='my-3' required={true}>
                                <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
                                <OutlinedInput
                                    id="confirm-password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    value={confirm_password}
                                    onChange={e => setConfirmPassword(e.target.value)}
                                    label='Confirm Password*'
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowConfirmPassword(showConfirmPassword ? false : true)}
                                            >
                                                {showConfirmPassword ? <LineIconLight name='unlock' size='md' /> : <LineIconLight name='lock' size='md' />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>

                            <PasswordPolicy show={showPasswordPolicy} />

                            {
                                success &&
                                <Typography variant='body2' className='my-2' color='secondary'>
                                    Password reset successful. Please <Link to='/signin'>Sign in</Link> to continue.
                            </Typography>
                            }
                            {
                                error
                                    ?
                                    <Typography variant='body2' color='error' className='my-1'>
                                        {error}
                                    </Typography>
                                    :
                                    null
                            }
                            <Button className={buttonLay} variant='contained' color='primary' type='submit' disabled={loading} >
                                {loading && <CircularProgress size={25} className='mr-1' />} Reset Password
                        </Button>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}