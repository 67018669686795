import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './styles.css';
import { primaryColor } from '../../theme';
import MultiSelect from 'multiselect-react-dropdown';

export default function LocationFilter({
    selectedLocs,
    multiple = true,
    setSelectedLocations,
    placeholder,
    view
}) {

    const { locations } = useSelector(state => state.userReducer);
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        if (Array.isArray(locations) && locations.length) {
            let arr = [];
            locations
            .forEach(e => {
                if(
                    view === 'SCHEDULER' &&
                    (
                        e.locationState?.isLocalPostApiDisabled ||
                        e.locationState?.isSuspended ||
                        !e.locationState?.isVerified ||
                        e.locationState?.isDisabled
                    )
                ) return;
                arr.push({
                    label: `${e.locationName} ${e.nickName ? `(${e.nickName})` : ''}`,
                    value: e
                })
            });
            if (multiple && arr.length) {
                arr.unshift({
                    label: 'All',
                    value: 'All'
                })
            }
            setOptions(arr);
        }
    }, [locations, multiple, view]);

    useEffect(() => {
        if (multiple) {
            setSelected(
                selectedLocs.length === (options.length - 1)
                    ?
                    [({ label: 'All', value: 'All' })]
                    :
                    selectedLocs.map(e => ({
                        label: `${e.locationName} ${e.nickName ? `(${e.nickName})` : ''}`,
                        value: e
                    }))
            );
        }
        else {
            if (selectedLocs)
                setSelected([{
                    label: `${selectedLocs.locationName} ${selectedLocs.nickName ? `(${selectedLocs.nickName})` : ''}`,
                    value: selectedLocs
                }]);
            else setSelected([]);
        }
    }, [selectedLocs, multiple, options]);

    const handleChange = (selectedList, selectedItem) => {
        if (!multiple)
            setSelectedLocations(selectedItem.value);
        else {
            if (selectedItem.value === 'All') {
                setSelectedLocations(options.slice(1).map(e => e.value));
            } else
                setSelectedLocations(selectedList.filter(e => e.value !== 'All').map(e => e.value))
        }
    };

    const handleRemove = (selectedList, removedItem) => {
        if (!multiple) return setSelectedLocations('');
        setSelectedLocations(
            selectedList
                .filter(e => e.value.name !== removedItem.value.name)
                .map(e => e.value)
        );
    }

    return (
        <React.Fragment>
            <MultiSelect
                options={options}
                selectedValues={selected}
                onSelect={handleChange}
                onRemove={handleRemove}
                displayValue="label"
                singleSelect={!multiple}
                avoidHighlightFirstOption={true}
                style={{
                    multiselectContainer: {
                        maxWidth: 500
                    },
                    searchBox: {
                        border: `1px solid ${primaryColor}`,
                        borderRadius: 5
                    },
                    inputField: {
                        maxWidth: Array.isArray(selected) && selected.length ? 10 : 200
                    },
                    chips: {
                        backgroundColor: primaryColor,
                        color: '#fff'
                    }
                }}
                placeholder={Array.isArray(selected) && selected.length ? '' : placeholder}
            />
        </React.Fragment>
    )
}