import { Button, Typography, TextField, ListItem, ListItemAvatar, Avatar, ListItemText, Divider } from "@material-ui/core";
import React, { useRef, useState } from "react";
// import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { AiOutlineSend } from 'react-icons/ai'
import { toast } from 'react-toastify';
import { _fetch } from "../../netReq";
import { useMixpanel } from 'react-mixpanel-browser';
import moment from "moment";
import LineIconLight from "../../react-lineicons-light";
import { Rating } from "@material-ui/lab";
import LineIconRegular from "../../react-lineicons-regular";



function getStarNum(star) {
	switch (star) {
		case 'ONE': return 1;
		case 'TWO': return 2;
		case 'THREE': return 3;
		case 'FOUR': return 4;
		case 'FIVE': return 5;
		default: return;
	}
}

function giveDateTime(givenDTFormat) {
	return moment(givenDTFormat).format('ll')
}

export default function ReviewView({
	name,
	profilePic,
	displayName,
	starRating,
	updateTime,
	comment,
	replyComment,
	replyTime
}) {

	const { accessToken } = useSelector(state => state.userReducer);
	const [clickReply, setClickReply] = useState(false);
	const reply = useRef('');
	const mixpanel = useMixpanel();

	function handleReply() {
		mixpanel.track('button click', { name, action: 'review_reply' });
		if (reply.current !== '') {
			_fetch(`${process.env.REACT_APP_GMB_URL}${name}/reply`,
				{ method: 'PUT', token: accessToken, body: { comment: reply.current } })
				.then((result) => {
					// setReviewInfo((st) => {
					// 	let obj = JSON.parse(JSON.stringify(st))
					// 	obj.reviewReply = {
					// 		comment: result.comment,
					// 		updateTime: result.updateTime
					// 	}
					// 	return obj
					// })
					toast.info('Reply posted, Reload!');
					setClickReply(false)
				},
					(error) => {
						process.env.NODE_ENV === 'development' && console.log(error)
					}
				)
				.catch(error => {
					process.env.NODE_ENV === 'development' && console.log(error)
				})


		}
	}

	function handleDelete() {
		_fetch(`${process.env.REACT_APP_GMB_URL}${name}/reply`, { method: 'DELETE', token: accessToken })
			.then((result) => {
				// setReviewInfo((st) => {
				// 	let obj = JSON.parse(JSON.stringify(st))
				// 	obj.reviewReply = undefined
				// 	return obj
				// })
				toast.info('Reply Deleted, Reload!');
			},
				(error) => {
					process.env.NODE_ENV === 'development' && console.log(error)
				})
			.catch(error => {
				process.env.NODE_ENV === 'development' && console.log(error)
			})
	}

	return (
		<React.Fragment>
			<ListItem alignItems="flex-start">
				<ListItemAvatar>
					<Avatar alt={displayName} src={profilePic} />
				</ListItemAvatar>
				<ListItemText
					primary={
						<div className='d-flex align-items-end'>
							<Rating
								icon={<LineIconLight name='star-fill' style={{ color: 'gold' }} />}
								emptyIcon={<LineIconLight name='star-fill' style={{ color: 'lightgrey' }} />}
								value={getStarNum(starRating)} readOnly
							/>
							<Typography className="ml-2" variant='caption' component='div'>
								{giveDateTime(updateTime)}
							</Typography>
						</div>
					}
					secondary={
						<React.Fragment>

							<Typography
								component="span"
								variant="body2"
								color="textPrimary"
								className='d-inline'
							>
								{displayName}
							</Typography>

							<Typography className="mt-1 mb-2" color={comment ? 'textPrimary' : 'textSecondary'} variant={comment ? 'body2' : 'subtitle2'}>
								{comment || 'This review has no comment'}
							</Typography>

							{/* OWNER comment */}

							<div>

								{
									replyComment &&
									<div className="mt-3 mb-3 pl-3 border-left">
										<div className='d-flex flex-row align-items-center'>
											<Typography variant='subtitle2'>Reply sent by you</Typography>
											<Typography className="ml-2" variant='caption'>
												{giveDateTime(replyTime)}
											</Typography>
										</div>
										<Typography className="mt-2 mb-1" color='textPrimary' variant='body2'>{replyComment}</Typography>
									</div>
								}

								{
									clickReply
										?
										<div className="d-flex flex-column m-1">
											<TextField label="Your Reply" multiline variant="outlined" color='secondary'
												onChange={(evt) => { reply.current = evt.target.value }}
											/>

											<div className='d-flex flex-row my-3'>

												<Button
													className="mr-2" color="secondary" variant="outlined"
													onClick={handleReply} size='small'
													endIcon={<AiOutlineSend />}
												>
													Send
												</Button>

												<Button color="primary" variant="outlined" size='small'
													onClick={() => setClickReply(false)}
													endIcon={<LineIconLight name='close' />}
												>
													Cancel
												</Button>

											</div>
										</div>
										:
										<div>

											<Button
												color="secondary" variant="outlined" size='small'
												onClick={() => setClickReply(true)} className="mr-2"
												endIcon={replyComment ?
													<LineIconRegular name='pencil' />
													:
													<AiOutlineSend />}
											>
												{replyComment ? 'Edit Reply' : 'Reply'}
											</Button>

											{
												replyComment &&
												<Button
													color="primary" variant="outlined" size='small'
													onClick={handleDelete}
													endIcon={<LineIconLight name='trash-can-alt-2' />}
												>
													Delete Reply
												</Button>
											}

										</div>
								}

							</div>
						</React.Fragment>
					}
				/>
			</ListItem>
			<Divider />
		</React.Fragment>
	);
}