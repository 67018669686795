import { Typography } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

export default function Verified() {

    let history = useHistory();
    let user_info = useSelector(st => st.userReducer);
    
    useEffect(()=>{
        if(user_info.account?.name) history.push('/');
    });

    return (
        <Paper className='h-100 p-3'>
            <Typography className='p-4'>
                Your account has been verified, <Link to='/signin'>Sign in</Link> to continue
            </Typography>
        </Paper>
    )
}