import { Button, Card, CardContent, CardHeader, CircularProgress, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { toast } from 'react-toastify';

import { _fetch } from "../../netReq";
import { initAccount } from "../../redux/action/action";
import { cardLay, buttonLay } from "./const";
import { useMixpanel } from 'react-mixpanel-browser';
import LineIconLight from "../../react-lineicons-light";

export default function AccountLogin() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [, setCookie] = useCookies();
    const [sent, setSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [goVerify, setGoVerify] = useState(false);
    const [signinError, setSigninError] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const mixpanel = useMixpanel();

    function handleResend() {
        _fetch(`${process.env.REACT_APP_API_URL}/account/send_verify`, {
            method: 'POST',
            body: {
                email: email,
                password: password
            }
        })
            .then(
                (result) => {
                    process.env.NODE_ENV === 'development' && console.log(result)
                    if (result.success) {
                        setSent(true)
                    }
                }
            )
    }

    function handleSignin(ev) {

        if (!email) return setSigninError('Email is required to login');
        if (!password) return setSigninError('Password is required to login');

        ev.preventDefault();
        setLoading(true);
        _fetch(`${process.env.REACT_APP_API_URL}/account/signin`, {
            method: 'POST',
            body: {
                email: email,
                password: password
            }
        })
            .then(
                (result) => {
                    process.env.NODE_ENV === 'development' && console.log(result)
                    const resp = result.response
                    if (result.success) {
                        if (!resp.verified)
                            return setGoVerify(true);

                        dispatch(initAccount(resp.payload));
                        mixpanel.identify(resp?.account?.id);
                        mixpanel.people.set({
                            email: resp?.account?.email,
                            name: resp?.account?.name
                        });
                        if (resp?.payload?.users?.length > 0) {
                            setCookie('account_id', resp.payload.users[0].account_id, { maxAge: 30 * 24 * 60 * 60 });
                        }
                        history.push('/');
                    }
                    else {
                        setSigninError(resp.message);
                    }
                },
                (error) => {
                    process.env.NODE_ENV === 'development' && console.log(error)
                }
            )
            .catch(err => {
                toast.error('Some error occurred. Please check you internet connectivity.')
            })
            .finally(() => {
                setLoading(false);
            });

        return false;
    }

    return (
        <Grid container justify="center" alignItems="center" className='flex-fill'>
            <Grid item xs={12} sm={8} md={6} className='my-auto'>
                <Card className={cardLay} elevation={3}>
                    <CardHeader title={<Typography variant='h4'> Sign In </Typography>} />
                    <CardContent>
                        {
                            sent
                                ?
                                <div>
                                    <Typography className='py-3'>
                                        A mail has been sent to your email address, please verify your account and
                                        <span className='text-primary' onClick={() => {
                                            setSent(false);
                                            setGoVerify(false);
                                        }}>
                                            sign in
                                        </span>
                                        again
                                    </Typography>
                                </div>
                                :
                                goVerify
                                    ?
                                    <div className='d-flex flex-column'>
                                        <Typography className='mx-auto my-2'>
                                            Please Verify your account first by going to the link sent to your email
                                </Typography>
                                        <Button className={buttonLay} variant='contained' color='primary'
                                            onClick={handleResend}
                                        >
                                            Resend link
                                </Button>
                                    </div>
                                    :
                                    <form className='d-flex flex-column' onSubmit={handleSignin}>
                                        <TextField variant='outlined'
                                            label='Email'
                                            required
                                            type='email'
                                            name='email'
                                            value={email}
                                            placeholder='Enter Email'
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                        <FormControl variant='outlined' className='my-3' required={true}>
                                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={password}
                                                onChange={e => {
                                                    setPassword(e.target.value);
                                                }}
                                                label='Password* '
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword(showPassword ? false : true)}
                                                        >
                                                            {showPassword ? <LineIconLight name='lock-alt-1' size='md' /> : <LineIconLight name='lock-alt-2' size='md' />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        {
                                            signinError
                                                ?
                                                <Typography variant='body2' color='error' className='my-1'>
                                                    {signinError}
                                                </Typography>
                                                :
                                                null
                                        }
                                        <Button className={buttonLay} variant='contained' color='primary' type='submit' disabled={loading}>
                                            {loading && <CircularProgress size={25} />} Sign In
                                        </Button>
                                        <Typography className='mt-4' variant='body1'>
                                            <Link to='/forgot_pass'>Forgot Password ? </Link>
                                        </Typography>
                                        <Typography className='mt-3' variant='body1'>
                                            Don't have an account ? <Link to='/register'>Sign Up</Link> and get 14 Days trial for free.
                                    </Typography>
                                    </form>

                        }
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}