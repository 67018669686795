import { toast } from "react-toastify";
import { _fetch } from "../../netReq";

export const initAccount = content => ({
	type: 'INIT_ACCOUNT',
	payload: content
});

export const currentUser = (obj) => ({
	type: 'CURRENT_USER',
	...obj
});

export const setLocations = (obj) => ({
	type: 'SET_LOCATION',
	...obj
});

export const setLoadingLocations = (loading) => ({
	type: 'SET_LOCATION_LOADING',
	loading
});

export const updateAccount = (obj) => ({
	type: 'UPDATE_ACCOUNT',
	payload: obj
});

export const setDeletedLocations = (obj) => ({
	type: 'SET_DELETED_LOCATION',
	payload: obj
})

export const toggleReviewMacro = (obj) => ({
	type: 'TOGGLE_REVIEW_MACRO',
	payload: obj
})

export const updateGUR = (obj) => ({
	type: 'UPDATE_GUR',
	payload: obj
})

export const addLocationStats = (obj) => ({
	type: 'ADD_LOCATION_STATS',
	payload: obj
})

export const setLocationStats = (obj) => ({
	type: 'SET_LOCATION_STATS',
	payload: obj
});

export const setLoadingStats = (loading) => ({
	type: 'SET_STATS_LOADING'
});

export const updateOpenStatus = (obj) => ({
	type: 'UPDATE_OPEN_STATUS',
	payload: obj
})

export const updateNickName = (obj) => ({
	type: 'UPDATE_NICKNAME',
	payload: obj
})

export const logout = () => ({
	type: 'LOGOUT'
});

export const loadLocationStats = (dispatch, names) => {
	if (Array.isArray(names) && names.length > 0)
		return _fetch(`${process.env.REACT_APP_API_URL}/location/stats?name=${JSON.stringify(names)}`)
			.then(r => {
				if (r.success) {
					dispatch(addLocationStats(r.response));
				}
			})
			.catch(err => {
				process.env.NODE_ENV === 'development' && console.log(err);
			})
}

export const loadLocation = (dispatch) => {
	dispatch(setLoadingStats());
	return _fetch(`${process.env.REACT_APP_API_URL}/location`)
		.then(async res => {
			if (res.status === 200) {
				console.log(res.response);
				dispatch(setLocations({ locations: res.response.locations }));
				loadLocationStats(dispatch, res.response.locations.map(e => e.name));
			} else dispatch(setLocations({ locations: [] }))
		})
		.catch(err => {
			process.env.NODE_ENV === 'development' && console.log(err);
			dispatch(setLocations({ locations: [] }));
		})
}

export const loadGoogleAccounts = (dispatch, account_id, current_user) => {
	return _fetch(`${process.env.REACT_APP_API_URL}/users`, { account_id: account_id })
		.then(async res => {
			if (res.status === 200) {
				dispatch(setLoadingStats());
				dispatch(currentUser({
					accessToken: res.response.accessToken,
					account_id: account_id,
					currentUser: current_user
				}));
				await loadLocation(dispatch);
			} else {
				toast.error(res.response);
				return null;
			}
		})
		.catch(err => {
			process.env.NODE_ENV === 'development' && console.log(err);
			dispatch(setLocations({ locations: [] }));
		});
}

export const loadAccount = (dispatch) => {
	return _fetch(`${process.env.REACT_APP_API_URL}/account`)
		.then(result => {
			if (result.success) {
				dispatch(initAccount(result.response));
				return result;
			} else {
				dispatch(initAccount({}));
			}
		})
		.catch(error => {
			console.log(error);
			dispatch(logout());
			toast.warning('Some error occurred please try again later.');
			process.env.NODE_ENV === 'development' && console.log(error)
		})
}
