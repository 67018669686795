import {
    Badge, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, Grid, IconButton, List, ListItem,
    ListItemIcon, ListItemSecondaryAction, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { _fetch } from "../../netReq";
import { blue } from "@material-ui/core/colors";
import { useHistory } from "react-router";
import moment from "moment";
import LineIconLight from "../../react-lineicons-light";
import LineIconRegular from '../../react-lineicons-regular'
import { primaryColor } from "../../theme";

const createOptions = () => {
    return {
        style: {
            base: {
                fontSize: '16px',
                color: '#424770',
                fontFamily: 'Open Sans, sans-serif',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#c23d4b',
            },
        }
    }
};

function CardComponent(card) {
    switch (card) {
        case 'visa':
            return <LineIconLight name='visa' style={{ color: primaryColor }} />
        case 'mastercard':
            return <LineIconRegular name='mastercard' style={{ color: primaryColor }} />
        case 'discover':
            return <LineIconRegular name='discover' style={{ color: primaryColor }} />
        case 'amex':
            return <LineIconLight name='amex' style={{ color: primaryColor }} />
        default:
            return <LineIconLight name='credit-card' style={{ color: primaryColor }} />
    }
}

export default function BillingManagement() {

    const red = useSelector(st => st.userReducer);
    const [payment_methods, setPaymentMethods] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [showCancellationDialog, setShowCancellationDialog] = useState(false);
    const [selectedCard, setSelectedCard] = useState('');
    const elements = useElements();
    const stripe = useStripe();
    const [updating, setUpdating] = useState(false);
    const [saving, setSaving] = useState(false);
    const history = useHistory();
    const [cancelling, setCancelling] = useState()

    async function getPaymentMethod() {
        try {
            setLoading(true);
            let { response, success } = await _fetch(`${process.env.REACT_APP_API_URL}/payment/payment_method`);
            if (success) {
                setPaymentMethods(response);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    async function getSubscription() {
        try {
            let { response, success } = await _fetch(`${process.env.REACT_APP_API_URL}/payment/subscription`);
            if (success) {
                setSubscription(response);
            }
        } catch (err) {
            console.log(err);
        }
    }

    async function getInvoices() {
        try {
            setLoading(true);
            let { response, success } = await _fetch(`${process.env.REACT_APP_API_URL}/payment/invoices`);
            if (success) {
                setInvoices(response);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getSubscription();
        getPaymentMethod();
        getInvoices();
    }, []);

    async function cancelSubscription() {
        try {
            setCancelling(true);
            let { success } = await _fetch(`${process.env.REACT_APP_API_URL}/payment/subscription`, { method: 'DELETE' });
            if (success) {
                setSubscription(null);
                setShowCancellationDialog(false);
                toast.success('Subscription cancelled successfully');
            }
        } catch (err) {
            console.log(err);
        } finally {
            setCancelling(false);
        }
    }

    async function removeCard() {
        try {
            setCancelling(true);
            let { success } = await _fetch(`${process.env.REACT_APP_API_URL}/payment/payment_method`, { method: 'DELETE', body: { pm_id: selectedCard } });
            if (success) {
                setPaymentMethods(arr => arr.filter(e => e.id !== selectedCard));
                setSelectedCard('');
                toast.success('Card removed successfully');
            }
        } catch (err) {
            console.log(err);
        } finally {
            setCancelling(false);
        }
    }

    async function savePaymentMethod() {
        try {
            setSaving(true);
            let { response, success } = await _fetch(`${process.env.REACT_APP_API_URL}/payment/payment_method`, { method: 'POST' });
            if (success) {
                let si = await stripe.confirmCardSetup(response, {
                    payment_method: {
                        card: elements.getElement(CardElement),
                        billing_details: {
                            name: red.account.name
                        },
                    },
                });

                if (si.error) {
                    toast.error(si.error.message);
                }
                else {
                    await getPaymentMethod();
                }
            }
            else {
                toast.error('Card setup failed');
            }
        } catch (err) {
            console.log(err);
            toast.error('Some error occurred while initiating subscription please try again later.');
        } finally {
            setSaving(false);
        }
    }

    async function updateSubscription(pm) {
        try {
            setUpdating(pm);

            let method = 'PATCH';

            if (!pm) return toast.error('Please select a payment method');

            let { success, response } = await _fetch(`${process.env.REACT_APP_API_URL}/payment/subscription`, {
                method: method,
                body: { payment_method: pm }
            });
            if (success) {
                if (response.client_secret) {
                    await stripe.confirmCardPayment(response.client_secret, {
                        payment_method: pm
                    });
                }
                await getSubscription();
                toast.success('Payment method updated successfully.');
            }
            else {
                toast.error('Failed to update payment method');
            }
        } catch (err) {
            console.log(err);
            toast.error('Some error occurred please try again later.');
        } finally {
            setUpdating('');
        }
    }

    return (
        <Paper className='d-flex flex-column w-100 m-auto p-3 h-100'>
            {
                loading ?
                    <CircularProgress className='m-auto' size={100} />
                    :
                    <React.Fragment>
                        <Typography className='pb-2' variant='h5' >Billing And Subscription Management</Typography>

                        <Grid container spacing={2} className='pt-4' alignItems='flex-start' >

                            <Grid item container spacing={2} xs={12} md={6}>

                                <Grid item xs={12}>
                                    <Card variant='outlined'>
                                        <CardHeader title='Manage subscription' />
                                        <CardContent className='d-flex'>
                                            {
                                                subscription ?
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={8} md={6}>
                                                            <Typography variant='body1'>Total subscribed slots</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>{subscription.items.data[0].quantity}</Grid>
                                                        <Grid item xs={8} md={6}>
                                                            <Typography variant='body1'>Next Renewal Date</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>{moment(subscription.current_period_end * 1000).format('LL')}</Grid>
                                                        <Grid item xs={8} md={6}>
                                                            <Typography variant='body1'>Monthly Recharge Value</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>₹{(subscription.items.data.reduce((prev, c) => prev + (c.quantity * c.plan.amount), 0)) / 100}</Grid>
                                                    </Grid>
                                                    :
                                                    <Button
                                                        variant='contained' className='m-auto' size='large'
                                                        onClick={() => history.push('/buy_slots')} color='primary'
                                                    >
                                                        Start Subscription
                                                    </Button>
                                            }
                                        </CardContent>
                                        {
                                            subscription &&
                                            <CardActions>
                                                <Button
                                                    variant='contained' color='primary' size='small'
                                                    onClick={() => history.push('/buy_slots')}
                                                >
                                                    Add/Remove Slots
                                                </Button>
                                                <Button
                                                    variant='contained' color='primary' size='small'
                                                    onClick={() => setShowCancellationDialog(true)}
                                                >
                                                    Cancel Subscription
                                                </Button>
                                            </CardActions>
                                        }
                                    </Card>
                                </Grid>

                                {
                                    Array.isArray(payment_methods) && payment_methods.length > 0 ?
                                        <Grid item xs={12}>
                                            <Card variant='outlined'>
                                                <CardHeader title='Manage Payment Methods' />
                                                <CardContent>
                                                    <Typography variant='body1'>Saved Payment Methods</Typography>
                                                    <List>
                                                        {
                                                            payment_methods.map(e => (
                                                                <ListItem button key={e.id}>
                                                                    <ListItemIcon>
                                                                        <Tooltip title={subscription?.default_payment_method === e.id ? `${e.card.brand} - Active Payment Method` : e.card.brand}>
                                                                            <Badge
                                                                                badgeContent={<LineIconLight name='checkmark-circle' style={{ color: blue['A700'] }} size='xs' />}
                                                                                invisible={subscription?.default_payment_method !== e.id}
                                                                            >
                                                                                {CardComponent(e.card.brand)}
                                                                            </Badge>
                                                                        </Tooltip>
                                                                    </ListItemIcon>
                                                                    <ListItemText>
                                                                        &emsp;**** {e.card.last4}
                                                                    </ListItemText>
                                                                    <ListItemSecondaryAction>
                                                                        {
                                                                            subscription?.default_payment_method !== e.id &&
                                                                            <Tooltip title='Set This As Active Payment Method' placement='top'>
                                                                                <IconButton
                                                                                    onClick={() => updateSubscription(e.id)}
                                                                                    disabled={!!updating}
                                                                                >
                                                                                    {
                                                                                        updating && updating === e.id ?
                                                                                            <CircularProgress color='primary' size={20} />
                                                                                            :
                                                                                            <LineIconLight name='check-circle' className='ml-auto' style={{ color: blue['A700'] }} />
                                                                                    }
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        }

                                                                        <IconButton
                                                                            className='ml-2' color='primary'
                                                                            onClick={() => setSelectedCard(e.id)}
                                                                            disabled={subscription?.default_payment_method === e.id}
                                                                        >
                                                                            <LineIconLight name='trash-can-alt-2' />
                                                                        </IconButton>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                            ))
                                                        }
                                                    </List>
                                                    <Typography variant='body1'>Add new card</Typography>
                                                    <div className='d-flex flex-row align-items-center mb-1'>
                                                        <div className='border p-2 rounded flex-fill'>
                                                            <CardElement {...createOptions()} />
                                                        </div>
                                                        <Button
                                                            variant='contained' color='primary' size='small'
                                                            onClick={savePaymentMethod} className='ml-2'
                                                            disabled={saving}
                                                            startIcon={saving ? <CircularProgress size={20} /> : null}
                                                        >
                                                            Save
                                                        </Button>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        :
                                        null
                                }
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Card variant='outlined'>
                                    <CardHeader title='Transaction History' />
                                    <Table aria-label="invoice table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Billing Period</TableCell>
                                                <TableCell align="right">Amount</TableCell>
                                                <TableCell align="right">Status</TableCell>
                                                <TableCell align="right"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Array.isArray(invoices) && invoices.map((row) => (
                                                <TableRow key={row.id}>
                                                    <TableCell>
                                                        {moment(row.lines?.data[0].period.start * 1000).format('ll')} - {moment(row.lines?.data[0].period.end * 1000).format('ll')}
                                                    </TableCell>
                                                    <TableCell align="right">₹{row.total / 100}</TableCell>
                                                    <TableCell align="right">{row.status === 'open' ? 'Payment Pending' : row.status}</TableCell>
                                                    <TableCell align="right">
                                                        {
                                                            row.status === 'open' ?
                                                                <Button
                                                                    onClick={() => window.open(row.hosted_invoice_url)}
                                                                    size='small' color='primary' variant='contained'
                                                                >
                                                                    Pay Now
                                                                </Button>
                                                                :
                                                                row.hosted_invoice_url &&
                                                                <a href={row.hosted_invoice_url} target='_blank' rel='noopener noreferrer'>
                                                                    <LineIconLight name='download' style={{ color: '#000' }} />
                                                                </a>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Card>
                            </Grid>

                        </Grid>
                    </React.Fragment>
            }

            <Dialog open={showCancellationDialog} onClose={() => setShowCancellationDialog(false)}>
                <DialogContent>
                    <div className='mb-2'>Are you sure you want to cancel your subscription ?</div>
                    <Typography variant='caption'>
                        PS: JeeMB subscription is prepaid. No refund will be issued if you cancel subscription midway.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setShowCancellationDialog(false)}
                        variant='contained' color='primary'
                        disabled={cancelling}
                    >
                        No, I don't Want to cancel
                    </Button>
                    <Button
                        onClick={cancelSubscription}
                        disabled={cancelling}
                        startIcon={cancelling ? <CircularProgress size={20} /> : null}
                        variant='contained' color='primary'
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={!!(selectedCard)} onClose={() => setSelectedCard('')}>
                <DialogContent>
                    Are you sure you want to remove this card ?
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setSelectedCard('')}
                        variant='contained' color='primary'
                        disabled={cancelling}
                    >
                        No
                    </Button>
                    <Button
                        onClick={removeCard}
                        disabled={cancelling}
                        startIcon={cancelling ? <CircularProgress size={20} /> : null}
                        variant='contained' color='primary'
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper >
    )
}