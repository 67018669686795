import { List, ListItem, ListItemText, Paper, Tooltip, Typography, Collapse } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FiSlash } from 'react-icons/fi';
import 'loaders.css';
// import { useMixpanel } from 'react-mixpanel-browser';
import { Alert } from "@material-ui/lab";
import { useCookies } from "react-cookie";
import './styles.css';
import ListingCard from './listingCard/index';
import HelpContent from "../helpContent.js";

const DeletedListingCard = ({ e }) => {
	return (
		<Paper className='my-2' variant='outlined'>
			<ListItem
				style={{ backgroundColor: '#F7F6F5' }}
			>
				<ListItemText
					primary={(
						<span>
							{e.locationName}

							<Tooltip title='This location has been deleted by google'>
								<span><FiSlash size={22} color='red' className='mx-2 my-auto' /></span>
							</Tooltip>
						</span>
					)} />
			</ListItem>
			<ListItem>
				<Typography color='textSecondary' variant='subtitle2'>
					This location has been deleted by google, it will be removed from next billing cycle
				</Typography>
			</ListItem>
		</Paper >
	)
}

export default function Home() {
	const red = useSelector(state => state.userReducer);
	// const history = useHistory();
	const [cookies, setCookie] = useCookies('alert');
	// const mixpanel = useMixpanel();
	const [alertOpen, setAlertOpen] = useState(false);

	useEffect(() => {
		if (cookies.alert) {
			if (!cookies.alert.closed)
				setAlertOpen(true);
			else
				setAlertOpen(false);
		}
		else
			setCookie('alert', {
				message: 'Now you can add your mobile number to receive instant SMS alerts.',
				closed: false
			}, {
				maxAge: 30 * 86400000
			})
	}, [cookies, cookies.alert, setCookie])

	return (
		<React.Fragment>
			<div className='h-100'>

				<Collapse in={alertOpen} style={{ marginBottom: 10 }}>
					<Alert severity='info' variant='filled' onClose={async () => {
						setAlertOpen(false);
						await setCookie('alert', {
							message: 'Now you can add your mobile number to receive instant SMS alerts.',
							closed: true
						}, {
							maxAge: 30 * 86400000
						})
					}}>

						{cookies.alert?.message + ' '}
						<Link to='/profile' style={{ color: 'white', textDecoration: 'underline' }}>Click Here</Link>
					</Alert>
				</Collapse>

				<div className='pb-3 d-flex flex-column h-100'>

					<div className='d-flex flex-column flex-md-row mb-2'>
						<HelpContent content={[
							'https://www.youtube.com/embed/rT7pveuh0xM',
							'https://www.youtube.com/embed/tLO0p9ZWqaw',
							'https://www.youtube.com/embed/RioSohg2RIw',
							'https://www.youtube.com/embed/B9fRNCzhfeg',
							'https://www.youtube.com/embed/HSfchIFtXtY',
							'https://www.youtube.com/embed/TpeAb72SmHA'
						]} />
					</div>

					{/* <Button
						className='ml-auto my-1' color='primary'
						onClick={() => {
							history.push('/login');
							mixpanel.track('button click', { referer: 'dashboard_bottom_button', action: 'navigate_to_add_listing' });
						}}
						startIcon={<IoAddCircleOutline size={25} color={pink['500']} />}
					>
						Add Listings
					</Button> */}
					<List style={{ height: '100%' }} disablePadding={true}>
						{
							Array.isArray(red.locations) && red.locations.length ?
								red.locations.map((e, i) => (
									<ListingCard
										e={e}
										userName={`${red.currentUser?.firstName} ${red.currentUser?.lastName}`}
										user_id={red.currentUser?.user_id}
										key={i}
										index={i}
										statsLoading={red.statsLoading}
									/>
								))
								:
								Array.isArray(red.deletedLocations) && red.deletedLocations.length &&
								red.deletedLocations.map((e, i) => (
									<DeletedListingCard e={e} key={i} />
								))
						}
					</List>
				</div >
			</div>
		</React.Fragment>
	)
}