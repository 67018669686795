import { Typography, TextField, Paper, IconButton, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AiOutlineSend } from 'react-icons/ai'
import { toast } from 'react-toastify';
import { _fetch } from "../../netReq";
import { useMixpanel } from 'react-mixpanel-browser';
import LineIconLight from "../../react-lineicons-light";

export default function ReviewView({
	name,
	comment,
	replyComment,
}) {

	const { accessToken } = useSelector(state => state.userReducer);
	const [reply, setReply] = useState('');
	const [replied, setReplied] = useState('');
	const mixpanel = useMixpanel();
	const [sending, setSending] = useState(false);

	useEffect(() => {
		setReply(replyComment ? replyComment : '');
		setReplied(!!(replyComment));
	}, [replyComment])

	function handleReply() {
		mixpanel.track('button click', { name, action: 'review_reply' });
		if (reply.current !== '') {
			setSending(true);
			_fetch(`${process.env.REACT_APP_GMB_URL}${name}/reply`,
				{ method: 'PUT', token: accessToken, body: { comment: reply.current } })
				.then((result) => {
					// setReviewInfo((st) => {
					// 	let obj = JSON.parse(JSON.stringify(st))
					// 	obj.reviewReply = {
					// 		comment: result.comment,
					// 		updateTime: result.updateTime
					// 	}
					// 	return obj
					// })
					toast.info('Reply posted, Reload!');
					setReply('');
				},
					(error) => {
						process.env.NODE_ENV === 'development' && console.log(error)
					}
				)
				.catch(error => {
					process.env.NODE_ENV === 'development' && console.log(error)
				})
				.finally(() => {
					setSending(false);
				});
		}
	}

	function handleDelete() {
		_fetch(`${process.env.REACT_APP_GMB_URL}${name}/reply`, { method: 'DELETE', token: accessToken })
			.then((result) => {
				// setReviewInfo((st) => {
				// 	let obj = JSON.parse(JSON.stringify(st))
				// 	obj.reviewReply = undefined
				// 	return obj
				// })
				toast.info('Reply Deleted, Reload!');
			},
				(error) => {
					process.env.NODE_ENV === 'development' && console.log(error)
				})
			.catch(error => {
				process.env.NODE_ENV === 'development' && console.log(error)
			})
	}

	return (
		<div className='w-100 h-100 pl-1 d-flex flex-column'>

			<Paper elevation={3} className='py-3 pl-3 w-75 ml-2 bg-light mb-3'>
				<Typography
					color={comment ? 'textPrimary' : 'textSecondary'}
					variant={comment ? 'body2' : 'subtitle2'}
					style={{ fontStyle: comment ? 'normal' : 'italic' }}
				>
					{comment || 'This review has no comment'}
				</Typography>
			</Paper>

			{
				replied ?
					<React.Fragment>
						<Paper elevation={3} className='p-3 w-75 ml-auto mr-2 d-flex' style={{ backgroundColor: '#e3ffed' }}>

							<Typography color='textPrimary' variant='body2'>{replyComment}</Typography>

							<IconButton
								color="primary" size='small'
								onClick={handleDelete} className='ml-auto'
							>
								<LineIconLight name='trash-can-alt-2' size='sm'/>
							</IconButton>

						</Paper>
					</React.Fragment>
					:
					null
			}

			<div className="mt-auto d-flex align-items-center ml-2 pb-2 mb-2">

				<TextField
					label={replied ? 'Update reply' : 'Reply'} variant="outlined"
					color='primary' size='small' value={reply}
					onChange={(evt) => setReply(evt.target.value)}
					className='flex-fill' id='reply'
				/>

				<IconButton color='primary' onClick={handleReply} disabled={sending || !reply} >
					{sending ? <CircularProgress size={15} /> : <AiOutlineSend />}
				</IconButton>

			</div>

		</div>
	);
}