import { Typography, TextField, Paper, IconButton, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
// import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { AiOutlineSend } from 'react-icons/ai'
import { toast } from 'react-toastify';
import { _fetch } from "../../netReq";
import { useMixpanel } from 'react-mixpanel-browser';
import LineIconLight from "../../react-lineicons-light";

export default function QuestionView({
	name,
	question,
	answer
}) {

	const { accessToken } = useSelector(state => state.userReducer);
	const [reply, setReply] = useState('');
	const mixpanel = useMixpanel();
	const [sending, setSending] = useState(false);

	useEffect(() => {
		setReply(Array.isArray(answer) ? answer.find(e => e.author.type === 'MERCHANT')?.text || '' : '');
	}, [answer]);

	function handleReply() {
		mixpanel.track('button click', { name, action: 'question_reply' });
		if (reply !== '') {
			setSending(true);
			_fetch(`${process.env.REACT_APP_GMB_URL}${name}/answers:upsert`, {
				method: 'POST', token: accessToken,
				body: {
					answer: {
						text: reply
					}
				}
			})
				.then((result) => {
					if (result) {
						toast.info('Answer posted, Reload!');
						setReply('');
					}
				},
					(error) => {
						process.env.NODE_ENV === 'development' && console.log(error)
					}
				)
				.catch(error => {
					process.env.NODE_ENV === 'development' && console.log(error)
				})
				.finally(() => {
					setSending(false);
				})


		}
	}

	function handleDelete() {
		_fetch(`${process.env.REACT_APP_GMB_URL}${name}/answers:delete`, { method: 'DELETE', token: accessToken })
			.then(res => res.json())
			.then((result) => {
				toast.info('Answer deleted, Reload!');
			},
				(error) => {
					process.env.NODE_ENV === 'development' && console.log(error)
				})
			.catch(error => {
				process.env.NODE_ENV === 'development' && console.log(error)
			})
	}

	return (
		<div className="pl-1 d-flex flex-column w-100 h-100">

			<Paper elevation={3} className='py-3 pl-3 w-75 ml-2 bg-light mb-3'>
				<Typography color='textPrimary' variant='body2' >
					{question}
				</Typography>
			</Paper>

			{
				Array.isArray(answer) ?
					answer.map((e, i) => (
						<React.Fragment key={i}>
							<Paper elevation={3} className='p-3 w-75 ml-auto mr-2' style={{ backgroundColor: '#e3ffed' }}>
								<div className='d-flex justify-content-between align-items-center'>
									<Typography color='textPrimary' variant='subtitle2'>{e.author.displayName}</Typography>
									{
										e.author.type === 'MERCHANT' ?
											<IconButton
												color="primary" size='small'
												onClick={handleDelete} className='ml-auto'
											>
												<LineIconLight name='trash-can-alt-2' size='sm'/>
											</IconButton>
											:
											null
									}
								</div>
								<Typography color='textPrimary' variant='body2'>{e.text}</Typography>
							</Paper>
						</React.Fragment>
					))
					:
					null
			}

			<div className="mt-auto d-flex align-items-center ml-2 mb-2 pb-2">

				<TextField
					label={answer?.find(e => e.author.type === 'MERCHANT') ? 'Update answer' : 'Write answer'} variant="outlined"
					color='primary' size='small' value={reply}
					onChange={(evt) => setReply(evt.target.value)}
					className='flex-fill' id='reply'
				/>

				<IconButton color='primary' onClick={handleReply} disabled={sending || !reply}>
					{sending ? <CircularProgress size={15} /> : <AiOutlineSend />}
				</IconButton>

			</div>

		</div>
	);
}