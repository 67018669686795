import React from 'react';
import { Hidden, Typography } from '@material-ui/core';

import './styles.css';

export default function OpenCloseLocation() {

    return (
        <div className='d-flex flex-column article-container'>
            <h3 className='mb-3 article-heading'>
                How to Open or Close Location your Listing from JeeMB
            </h3>
            <Typography variant='subtitle2' className='mb-4'>
                Last updated: September 23, 2021
            </Typography>

            <Hidden smUp>
                <iframe
                    className='mx-auto' width="320" height="180" src='https://www.youtube.com/embed/B9fRNCzhfeg'
                    title="YouTube video player" frameborder="0" allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
            </Hidden>
            <Hidden only={['xs', 'md', 'lg', 'xl']}>
                <iframe
                    className='mx-auto' width="560" height="315" src='https://www.youtube.com/embed/B9fRNCzhfeg'
                    title="YouTube video player" frameborder="0" allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
            </Hidden>
            <Hidden only={['xs', 'sm']}>
                <iframe
                    className='mx-auto' width="720" height="405" src='https://www.youtube.com/embed/B9fRNCzhfeg'
                    title="YouTube video player" frameborder="0" allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
            </Hidden>

            <p className='article-content'>
                In this tutorial, we will learn how to open or close the location. Google My Business provides the facility to either temporarily close or permanently close the business. You can also use the same functionality using the JeeMB, you can easily use this function. Use the following steps:
            </p>

            <ol>
                <li className='article-content'>
                    Login to your JeeMB account and the dashboard will appear. In the given screen, check for the Power button.
                    <p className='mt-2'>
                        Note: If this button is turned off, then you might see a greyed-out power button. Otherwise, when the listing is open, you will see a green power button.
                    </p>
                    <img src={`${process.env.REACT_APP_AWS_BUCKET}img/power-button-1-3.png`} alt='...' className='article-image img img-fluid' />
                </li>
                <li className='article-content'>
                    To temporarily close it, click on the power button, a pop-up will display over and seek validation for your work. Select ‘Confirm’ to deactivate the listings.
                    <img src={`${process.env.REACT_APP_AWS_BUCKET}img/Click-on-power-button-3(2).png`} alt='...' className='article-image img img-fluid' />
                </li>
            </ol>

            <p className='article-content'>
                For safety concerns, JeeMB only allows you to temporarily close the listing and does not provide the facility to shut it down completely.
            </p>

            <h6 className='article-subheading'>
                Permanently Close Google Listings:
            </h6>

            <p className='article-content'>
                If you wish to close the Google Listings, then you have to do it on your own. Browse for Google.com/Business and do accomplish the action from the Google My Business dashboard.
            </p>

        </div>
    );

}