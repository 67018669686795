import React from "react";
import "./lineIcons-pro-regular.css";

/**
 * 
 * @param {String} name name of the icon
 * @param {("lg"|"md"|"sm"|"xs")} size name of the icon
 * @param {("spin" | "tada" | "flashing" | burst" | "fade-left" | "fade-right" | "fade-up" | "fade-down")} effect animation to apply
 * @param {React.CSSProperties} style name of the icon
 * @param {React.ReactNode} tag anything that can be rendered for example 'div', 'span', 'i' etc
 * @param {string} className other classes you want to apply
 * @returns 
 */
export default function LineIconRegular (props) {
	const { name, size = 'md', effect = '', style = {}, tag: Tag = 'i', className, ...rest } = props;
	return (
		<Tag
			className={`lni lni-${name} li-size-${size} ${effect ? `lni-${effect}-effect` : ""} ${className}`}
			style={style}
			{...rest}
		/>
	);
};
