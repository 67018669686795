import update from 'immutability-helper';

const initialState = {
    account: {},
    currentUser: {},
    users: [],
    account_id: '',
    accessToken: '',
    locations: [],
    deletedLocations: [],
    statsLoaded: false,
    statsLoading: false,
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case 'INIT_ACCOUNT': {
            return ({
                ...state,
                ...action.payload
            });
        }
        case 'CURRENT_USER': {
            return ({
                ...state,
                account_id: action.account_id,
                accessToken: action.accessToken,
                currentUser: action.currentUser,
                locations: [],
                statsLoaded: false,
            });
        }
        case 'UPDATE_ACCOUNT': {
            return ({
                ...state,
                account: action.payload
            })
        }
        case 'SET_LOCATION': {
            return ({
                ...state,
                locations: action.locations,
                statsLoading: Array.isArray(action.locations) && action.locations.length > 0 ? true : false
            });
        }
        case 'SET_LOCATION_LOADING': {
            return ({
                ...state,
                loadingLocations: action.loading
            });
        }
        case 'SET_DELETED_LOCATION': {
            return ({
                ...state,
                deletedLocations: action.payload
            })
        }
        case 'TOGGLE_REVIEW_MACRO': {
            return (update(state, {
                locations: {
                    [action.payload.index]: {
                        reviewMacroActive: {
                            $set: action.payload.active
                        }
                    }
                }
            }))
        }
        case 'ADD_LOCATION_STATS': {
            process.env.NODE_ENV === 'development' && console.log(action.payload);
            return ({
                ...state,
                locations: action.payload,
                statsLoaded: true,
                statsLoading: false
            });
        }
        case 'SET_LOCATION_STATS': {
            process.env.NODE_ENV === 'development' && console.log(action.payload);
            return (update(state, {
                locations: {
                    [action.payload.index]: {
                        score: {
                            $set: action.payload.score
                        },
                        recommendations: {
                            $set: action.payload.recommendations
                        },
                        totalRatings: {
                            $set: action.payload.totalRatings
                        },
                        averageRating: {
                            $set: action.payload.averageRating
                        },
                        totalQuestions: {
                            $set: action.payload.totalQuestions
                        },
                        totalScheduledPost: {
                            $set: action.payload.totalScheduledPost
                        },
                        nickName: {
                            $set: action.payload.nickName
                        }
                    }
                }
            }))
        }
        case 'SET_STATS_LOADING': {
            return ({
                ...state,
                statsLoading: true
            });
        }
        case 'UPDATE_GUR': {
            if (state.locations.length <= action.payload.index)
                return state;
            return (update(state, {
                locations: {
                    [action.payload.index]: {
                        GUR: {
                            active: { $set: action.payload.active },
                            userName: { $set: action.payload.userName }
                        }
                    }
                }
            }))
        }
        case 'LOGOUT': {
            return ({});
        }
        case 'UPDATE_OPEN_STATUS': {
            return (update(state, {
                locations: {
                    [action.payload.index]: {
                        openInfo: {
                            status: { $set: action.payload.status }
                        }
                    }
                }
            }))
        }
        case 'UPDATE_NICKNAME': {
            return (update(state, {
                locations: {
                    [action.payload.index]: {
                        nickName: { $set: action.payload.nickName }
                    }
                }
            }))
        }
        default: {
            return state;
        }
    }
}