import { CircularProgress, Paper, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { _fetch } from "../../netReq";
import { useMixpanel } from 'react-mixpanel-browser';
import { useSelector } from "react-redux";

export default function SearchVideos() {

    const { search_query } = useSelector(st => st.appReducer ?? { search_query: '' });
    const [loading, setLoading] = useState(false);
    const mixpanel = useMixpanel();
    const [videos, setVideos] = useState([]);

    const _search = useCallback(async () => {
        try {
            setLoading(true);
            mixpanel.track('search', { query: search_query });
            let { response, success } = await _fetch(`${process.env.REACT_APP_API_URL}/search?query=${search_query}`);
            if (success) {
                setVideos(response);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, [search_query, mixpanel]);

    useEffect(() => {
        _search();
    }, [_search])

    if (loading) {
        return (
            <Paper className='min-h-100 d-flex' elevation={0}>
                <CircularProgress size={75} />
            </Paper>
        )
    }

    return (
        <div className='d-flex flex-column h-100 p-3'>

            {
                Array.isArray(videos) && videos.length > 0 ?
                    <Paper className='min-h-100 d-flex flex-column p-4' elevation={0}>
                        {
                            videos.map((e, i) => (
                                <div className='d-flex px-3 py-5'>
                                    <iframe key={i}
                                        width="320" height="180" src={e.url}
                                        title="YouTube video player" frameborder="0" allowFullScreen style={{ minHeight: 169 }}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    />
                                    <div className='pl-4'>
                                        <Typography variant='h5'>{e.title}</Typography>
                                        <Typography variant='body1'>
                                            {e.description}
                                        </Typography>
                                    </div>
                                </div>
                            ))
                        }
                    </Paper>
                    :
                    <Paper className='min-h-100 d-flex p-4' elevation={0}>
                        <p>We were unable to find any video related to your search.</p>
                    </Paper>
            }

        </div>
    )
}