import { Card, CardContent, CardHeader, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useLocation, Link } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function Error() {

    let query = useQuery();

    return (
        <Paper className='h-100 flex-fill d-flex'>
            <Card className='m-auto' elevation={0}>
                <CardHeader title={<Typography variant='h4'> {query.get('status') || 404} {query.get('title') || 'Not Found'} </Typography>} />
                <CardContent>
                    {query.get('message') || 'The page you are looking for was not found.'}
                    <p className='mt-2'>
                        <Link to='/'>Go back to home -&gt; </Link>
                    </p>
                </CardContent>
            </Card>
        </Paper>
    )
}