import { Button, Card, CardContent, CardHeader, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@material-ui/core";
import { toast } from 'react-toastify';
import React, { useState } from "react";

import { _fetch } from "../../netReq";
import { cardLay } from "./const";
import { Link } from "react-router-dom";
import validator from "validator";
import PasswordPolicy from "./PasswordPolicy";
import LineIconLight from "../../react-lineicons-light";

export default function Registration() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [stageAccessPassword, setStageAccessPassword] = useState(false);
    const [name, setName] = useState('');
    const [insteadOf, setInsteadOf] = useState('');
    const [loading, setLoading] = useState(false);
    const [err, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [checked, setChecked] = useState(false);
    const [showPasswordPolicy, setShowPasswordPolicy] = useState(false);

    function handleClick(ev) {

        ev.preventDefault();

        setError(false);
        setShowPassword(false);

        if (!name) return setError('Name is required to register');
        if (!email) return setError('Email is required to register');
        if (!password) return setError('Password is required to register');
        if (!validator.isEmail(email)) return setError('Please enter a valid email')
        if (!validator.isStrongPassword(password)) {
            setError('Password does not follow password policy');
            return setShowPasswordPolicy(true);
        }

        setLoading(true);
        _fetch(`${process.env.REACT_APP_API_URL}/account/register`, {
            method: 'POST',
            body: {
                name: name,
                email: email,
                password: password,
                stage_access_password: process.env.REACT_APP_ENV === 'stage' ? stageAccessPassword : ''
            }
        })
            .then(result => {

                process.env.NODE_ENV === 'development' && console.log(result)
                if (result.response?.registered) {
                    setInsteadOf('Please go to your email and verify your account')
                }
                else if (result.response.exists)
                    setInsteadOf(<span>Account already exists, <Link to='/signin'>Sign in</Link></span>)
                else toast.error(result.response)
            })
            .catch(err => {

                toast.error('Some error occurred. Please check you internet connectivity.')
            })
            .finally(() => setLoading(false));
        return false;
    }

    return (
        <Grid container justify="center" alignItems="center" className='flex-fill'>
            <Grid item xs={12} sm={8} md={6} className='my-auto'>
                <Card className={cardLay} elevation={3}>
                    <CardHeader title={<Typography variant='h4'> Register </Typography>} />
                    <CardContent>
                        {
                            insteadOf
                                ?
                                <div>
                                    <Typography className='py-3'>
                                        {insteadOf}
                                    </Typography>
                                </div>
                                :
                                <form className='d-flex flex-column' onSubmit={handleClick}>
                                    <TextField
                                        variant='outlined'
                                        label='Full Name'
                                        name='Name'
                                        required
                                        value={name}
                                        placeholder='Enter Your Full Name'
                                        onChange={e => setName(e.target.value)}
                                    />
                                    <TextField variant='outlined'
                                        required
                                        label='Email'
                                        className='mt-3'
                                        type='email'
                                        name='email'
                                        value={email}
                                        placeholder='Enter Email'
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                    <FormControl variant='outlined' className='my-3' required={true}>
                                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            value={password}
                                            onChange={e => {
                                                setPassword(e.target.value);
                                            }}
                                            label='Password *'
                                            required
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword(showPassword ? false : true)}
                                                    >
                                                        {showPassword ? <LineIconLight name='lock-alt-1' size='md' /> : <LineIconLight name='lock-alt-2' size='md' />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>

                                    {
                                        process.env.REACT_APP_ENV === 'stage' &&
                                        <FormControl variant='outlined' className='my-3' required={true}>
                                            <InputLabel htmlFor="outlined-adornment-password">Stage Access Password</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={stageAccessPassword}
                                                onChange={e => {
                                                    setStageAccessPassword(e.target.value);
                                                }}
                                                label='Stage Access Password *'
                                                required
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword(showPassword ? false : true)}
                                                        >
                                                            {showPassword ? <LineIconLight name='unlock' size='md' /> : <LineIconLight name='lock' size='md' />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    }

                                    <PasswordPolicy show={showPasswordPolicy} />

                                    <FormControlLabel
                                        control={<Checkbox checked={checked} size='small' onChange={() => setChecked(!checked)} name="checkedA" />}
                                        label={
                                            <span>
                                                I agree to
                                            <a href='https://jeemb.com/terms.html' target="_blank" rel="noopener noreferrer"> JeeMB T&amp;C</a>
                                            </span>}
                                    />
                                    <Typography variant='body1' className='mb-3'>
                                        Already have an account ? <Link to='/signin'> Sign In</Link>
                                    </Typography>
                                    {err && <p className='text-danger'>{err}</p>}
                                    <Button
                                        color='primary' className={'w-75 mx-auto py-2'} variant='contained'
                                        onClick={handleClick} type='submit' disabled={loading || !checked}
                                    >
                                        {loading && <CircularProgress size={25} className='mr-1' />} Register for Free Trial
                                </Button>
                                    <Typography variant='h6' className='text-center mt-3'>No Credit Card Required</Typography>
                                </form>
                        }
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}