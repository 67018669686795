import React from 'react';
import { Hidden, Typography } from '@material-ui/core';

import './styles.css';

export default function AddNickname() {

    return (
        <div className='d-flex flex-column article-container'>
            <h3 className='mb-3 article-heading'>
                How to add Nickname in your listing
            </h3>
            <Typography variant='subtitle2' className='mb-4'>
                Last updated: September 23, 2021
            </Typography>


            <Hidden smUp>
                <iframe
                    className='mx-auto' width="320" height="180" src='https://www.youtube.com/embed/HSfchIFtXtY'
                    title="YouTube video player" frameborder="0" allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
            </Hidden>
            <Hidden only={['xs', 'md', 'lg', 'xl']}>
                <iframe
                    className='mx-auto' width="560" height="315" src='https://www.youtube.com/embed/HSfchIFtXtY'
                    title="YouTube video player" frameborder="0" allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
            </Hidden>
            <Hidden only={['xs', 'sm']}>
                <iframe
                    className='mx-auto' width="720" height="405" src='https://www.youtube.com/embed/HSfchIFtXtY'
                    title="YouTube video player" frameborder="0" allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
            </Hidden>

            <p className='article-content'>
                Do you find it difficult in managing the cluster of listings? Don’t worry! By using JeeMB, you can Manage your Listing in a better way!
            </p>

            <p className='article-content'>
                JeeMB offers the unique facility of enlisting your listing by giving a nickname to your listing. It helps in distinguishing between different Follow the step-by-step guide to assign nicknames to your listing.
            </p>

            <ol>
                <li className='article-content'>
                    Log in to JeeMB and proceed to the dashboard. From here, click on the ‘Add Nickname’ option visible right next to your listing.
                    <img src={`${process.env.REACT_APP_AWS_BUCKET}img/add+nickname-2.png`} alt='...' className='article-image img img-fluid' />
                </li>
                <li className='article-content'>
                    A pop-up of ‘Let’s give a nickname to your listing’ will appear, enter the nickname you wish to assign to the listing.
                    <img src={`${process.env.REACT_APP_AWS_BUCKET}img/nickname-dialog-box-3.png`} alt='...' className='article-image img img-fluid' />
                </li>
                <li className='article-content'>
                    Ensure to Press the ‘Save’ button to save the changes. If you fail to save the changes, the nickname will not be assigned to the given listing.
                    <img src={`${process.env.REACT_APP_AWS_BUCKET}img/save-nickname-4.png`} alt='...' className='article-image img img-fluid' />
                </li>
                <li className='article-content'>
                    After Saving the Nickname, you will be able to see the nickname visible in the dashboard.
                    <img src={`${process.env.REACT_APP_AWS_BUCKET}img/nickname-saved.png`} alt='...' className='article-image img img-fluid' />
                </li>
            </ol>

            <p className='article-content'>
                Pls, Note: The nickname is only used for managing the listing saved in the JeeMB account. This will not be used in the Google My Business Listing.
            </p>

        </div>
    );

}