import React, { useState, } from 'react';
import { Button, Dialog, DialogContent, DialogActions, IconButton, Hidden } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';

export default function HelpContent({ content = [] }) {

    const [helpModal, setHelpModal] = useState(false);

    return (
        <React.Fragment>
            <IconButton
                color='primary' className='ml-auto'
                onClick={() => setHelpModal(true)}
                style={{ fontSize: 30, fontWeight: 600, lineHeight: '30px', width: 50, height: 50 }}
            >
                ?
            </IconButton>
            <Dialog open={helpModal} onClose={() => setHelpModal(false)} maxWidth='md'>
                <DialogContent>

                    <Carousel autoPlay={false} navButtonsAlwaysVisible >
                        {
                            content.map((e, i) => (
                                <React.Fragment>
                                    <Hidden smUp>
                                        <iframe key={i} className='mx-auto' width="400" height="225" src={e} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </Hidden>
                                    <Hidden only={['xs', 'md','lg', 'xl']}>
                                        <iframe key={i} className='mx-auto' width="560" height="315" src={e} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </Hidden>
                                    <Hidden only={['xs', 'sm']}>
                                        <iframe key={i} className='mx-auto' width="720" height="405" src={e} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </Hidden>
                                </React.Fragment>
                            ))
                        }
                    </Carousel>

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setHelpModal(false)}
                        variant='contained' color='primary'
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}