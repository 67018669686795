import { DialogContent, DialogTitle, TextField, Typography, DialogActions, Button, Dialog, IconButton, Card, CardContent, CardActions, CardHeader, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { _fetch } from "../../../netReq";
import { useDispatch } from "react-redux";
import { toggleReviewMacro } from "../../../redux/action/action";
import { useMixpanel } from 'react-mixpanel-browser';
import { Rating } from "@material-ui/lab";
import { IOSSwitch } from "../../../resources/IOSSwitch/IOSSwitch";
import { primaryColor } from "../../../theme";
import LineIconLight from "../../../react-lineicons-light";
import LineIconRegular from "../../../react-lineicons-regular";

export default function ReviewMacro({
    user_id, locationId, active, index
}) {
    const [macro, setMacro] = useState({});
    const dispatch = useDispatch();
    const [openReviewMacro, setOpenReviewMacro] = useState(false);
    const [macrosOn, setMarcosOn] = useState(false)
    const [submitLoader, setSubmitLoader] = useState(false);
    const [errorMess, setErrorMess] = useState('')
    const mixpanel = useMixpanel();

    useEffect(() => {
        if (!active)
            setMarcosOn(false);
        else setMarcosOn(true);
    }, [active]);

    function handleEdit() {
        process.env.NODE_ENV === 'development' && console.log('handleEdit')
        _fetch(`${process.env.REACT_APP_API_URL}/review_macro?locationId=${locationId}`)
            .then(r => {
                process.env.NODE_ENV === 'development' && console.log(r);
                if (r.success) {
                    setOpenReviewMacro(true)
                    setMacro(r.response);
                }
                else {
                    toast.error(r.response.message)
                }
            }).catch(err => {
                toast.error(err);
            })
    }

    function handleSwitch() {
        mixpanel.track('switch flip', { status: !macrosOn, locationId: locationId, action: 'change_review_macro_status' });

        _fetch(`${process.env.REACT_APP_API_URL}/review_macro`, {
            method: 'PATCH',
            body: {
                locationId: locationId,
                user_id: user_id,
                active: !macrosOn
            }
        })
            .then(r => {
                process.env.NODE_ENV === 'development' && console.log(r)
                if (r.success) {
                    if (r.response.firstTime) {
                        setOpenReviewMacro(true);
                        setMacro({
                            locationId: locationId,
                            user: user_id,
                            ONE: [],
                            TWO: [],
                            THREE: [],
                            FOUR: [],
                            FIVE: []
                        })
                    }
                    setMarcosOn(r.response.active);
                    dispatch(toggleReviewMacro({ index: index, active: r.response.active }))
                    if (r.response.active === false) {
                        setOpenReviewMacro(false);
                    }
                    setErrorMess('')
                }
            }).catch(err => {
                toast.error(err);
            })
    }

    function handleSubmit() {
        process.env.NODE_ENV === 'development' && console.log('handleSubmit')
        process.env.NODE_ENV === 'development' && console.log(macro)


        if (!macro.ONE.length && !macro.TWO.length && !macro.THREE.length && !macro.FOUR.length && !macro.FIVE.length)
            return setErrorMess('Add alteast one reply to submit')
        if (macro.ONE.length && macro.ONE.filter(e => e.length === 0).length)
            return setErrorMess('One or more field(s) left empty in reply for One star')
        if (macro.TWO.length && macro.TWO.filter(e => e.length === 0).length)
            return setErrorMess('One or more field(s) left empty in reply for Two star')
        if (macro.THREE.length && macro.THREE.filter(e => e.length === 0).length)
            return setErrorMess('One or more field(s) left empty in reply for Three star')
        if (macro.FOUR.length && macro.FOUR.filter(e => e.length === 0).length)
            return setErrorMess('One or more field(s) left empty in reply for Four star')
        if (macro.FIVE.length && macro.FIVE.filter(e => e.length === 0).length)
            return setErrorMess('One or more field(s) left empty in reply for Five star')

        setErrorMess('')
        setSubmitLoader(true);
        let body = {
            ...macro
        }
        process.env.NODE_ENV === 'development' && console.log(body);
        _fetch(`${process.env.REACT_APP_API_URL}/review_macro`, {
            method: 'POST',
            body: body
        })
            .then(r => {
                if (r.success)
                    toast.info('Automatic Review Reply Saved')
                else
                    toast.error(r.response)
                setOpenReviewMacro(false)
                setSubmitLoader(false)
            }).catch(err => {
                toast.error(err)
            })
    }

    function makeInput(star, msgs) {

        let num = 0;

        switch (star) {
            case 'ONE': num = 1;
                break;
            case 'TWO': num = 2;
                break;
            case 'THREE': num = 3;
                break;
            case 'FOUR': num = 4;
                break;
            case 'FIVE': num = 5;
                break
            default: return;
        }

        return (
            <Card elevation={3} className={`${num === 5 ? '' : 'mb-3'} border rounded`}>
                <CardHeader style={{ backgroundColor: '#F7F6F5' }}
                    title={
                        <div className='d-flex align-items-center'>
                            <Typography className='my-auto' variant='h6' style={{ fontWeight: 500 }}>
                                Set Custom Reply For {star} Star Rating/Review
                            </Typography>
                            <Rating
                                className='ml-2 align-middle'
                                readOnly value={num}
                                icon={<LineIconLight name='star-fill' size='sm' style={{ color: 'gold' }} />}
                                emptyIcon={<LineIconLight name='star-fill' size='sm' style={{ color: 'lightgrey' }} />}
                            />
                        </div>
                    }
                />
                <CardContent>
                    {
                        !msgs.length &&
                        <div className='mb-1'>
                            <Button className='w-100 d-flex pointer'
                                color='primary'
                                onClick={() => {
                                    let temp = macro[star];
                                    temp.push('')
                                    setMacro({ ...macro, [star]: temp })
                                }}
                                style={{ height: 60 }}
                                startIcon={<LineIconRegular name='plus' size='xs' />}
                            >Add Custom Reply</Button>
                        </div>
                    }
                    {
                        msgs.map((e, i) => (
                            <div key={i} className={`${i === msgs.length - 1 ? '' : 'mb-2'}`}>
                                <TextField key={i}
                                    fullWidth
                                    multiline
                                    label={`Custom Reply ${i + 1}`}
                                    rows={1}
                                    rowsMax={2}
                                    size='small'
                                    value={e} placeholder='Type a Reply'
                                    InputProps={{
                                        endAdornment:
                                            <IconButton size='small' onClick={() => {
                                                let temp = [...macro[star]]
                                                temp.splice(i, 1)
                                                setMacro({ ...macro, [star]: temp })
                                            }}>
                                                <LineIconRegular name='close' size='xs' />
                                            </IconButton>
                                    }}
                                    onChange={(ev) => {
                                        let temp = macro[star]
                                        temp[i] = ev.target.value
                                        setMacro({ ...macro, [star]: temp })
                                    }}
                                />
                            </div>
                        ))

                    }
                </CardContent>
                <CardActions>
                    {
                        star !== 'FIVE' ?
                            <Button color='primary' size='small' className='mr-1'
                                variant='contained'
                                startIcon={<LineIconLight name='angle-double-down' size='xs' />}
                                onClick={() => {
                                    let temp;
                                    switch (star) {
                                        case 'ONE': temp = [...macro.TWO];
                                            break;
                                        case 'TWO': temp = [...macro.THREE];
                                            break;
                                        case 'THREE': temp = [...macro.FOUR];
                                            break;
                                        case 'FOUR': temp = [...macro.FIVE];
                                            break;
                                        default: return;
                                    }
                                    setMacro({ ...macro, [star]: temp })
                                }}
                            >
                                Copy From Below</Button>
                            : null
                    }
                    {
                        macro[star].length ?
                            <Button color='primary' size='small'
                                variant='contained'
                                disabled={macro[star].length > 4}
                                onClick={() => {
                                    let temp = macro[star];
                                    temp.push('')
                                    setMacro({ ...macro, [star]: temp })
                                }}
                                startIcon={<LineIconRegular name='plus' size='xs' />}
                            >Add Reply</Button> : null
                    }
                    {
                        star !== 'ONE' ?
                            <Button color='primary' size='small'
                                variant='contained'
                                startIcon={<LineIconLight name='angle-double-up' size='xs' />}
                                onClick={() => {
                                    let temp;
                                    switch (star) {
                                        case 'TWO': temp = [...macro.ONE];
                                            break;
                                        case 'THREE': temp = [...macro.TWO];
                                            break;
                                        case 'FOUR': temp = [...macro.THREE];
                                            break;
                                        case 'FIVE': temp = [...macro.FOUR];
                                            break;
                                        default: return;
                                    }
                                    setMacro({ ...macro, [star]: temp })
                                }}
                            >Copy From Above</Button> : null
                    }
                </CardActions>
            </Card>
        )
    }

    return (
        <div className='d-flex py-2 w-100 align-items-center justify-content-between'>

            <div>
                <Typography className='d-flex'>
                    {
                        macrosOn ?
                            <LineIconLight size='md' name='reply' style={{ color: primaryColor }} className='mr-3' />
                            :
                            <LineIconLight size='md' name='reply' className='mr-3 align-top' />
                    }
                    Automatic Review Reply
                </Typography>
            </div>

            <div>
                {
                    macrosOn ?
                        <Button size='small' style={{ borderRadius: '15px' }} onClick={handleEdit} color='primary' variant='outlined'>
                            edit
                        </Button>
                        :
                        null
                }
                <IOSSwitch className='ml-4' checked={macrosOn} color='primary'
                    onClick={handleSwitch}
                />
            </div>
            <Dialog scroll='paper' open={openReviewMacro} onClose={() => setOpenReviewMacro(false)} fullWidth maxWidth='md'>
                <DialogTitle >
                    <div className='d-flex'>
                        <h5 className='my-auto'>Automatic Review Reply</h5>
                        <IconButton className='ml-auto' onClick={() => setOpenReviewMacro(false)}>
                            <LineIconRegular name='close' size='xs' />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    {macro.ONE && makeInput('ONE', macro.ONE)}
                    {macro.TWO && makeInput('TWO', macro.TWO)}
                    {macro.THREE && makeInput('THREE', macro.THREE)}
                    {macro.FOUR && makeInput('FOUR', macro.FOUR)}
                    {macro.FIVE && makeInput('FIVE', macro.FIVE)}

                </DialogContent>
                <DialogActions className='d-flex'>
                    {
                        errorMess
                            ?
                            <Typography className='my-auto' variant='subtitle2' color='error'>{errorMess}</Typography>
                            :
                            null
                    }
                    <Button variant='contained' color='primary' size='small' onClick={handleSubmit}>
                        {
                            submitLoader
                                ?
                                <CircularProgress size={10} />
                                :
                                'Submit'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}