import React from 'react';
import { Paper } from '@material-ui/core';

export default function RefundAndCancellation() {
    return (
        <Paper className='w-75 my-auto mx-auto p-4'>
            <h2>Refund and Cancellation Policy for JeeMB</h2>
            <p>JeeMB is a Google My Business listing management suit that tries to offer various tools in one dashboard. We offer 14 days free trial period that anyone can signup without requiring any credit card information.</p>

            <p>You can cancel your subscription by going into your listing management section and clicking on the cancel subscription button.</p>

            <p>Cancellation request: You may cancel your subscription at any time. JeeMB is offered at pre-paid payment model. Your cancellation request will take effect at the end of the pre-paid period. </p>

            <p>Refund request. JeeMB offers a fully-functional 14 days trial period during which you can test all features. After trial period, JeeMB is offered at pre-paid monthly plan. We do not offer any refund on monthly payments and all payments made for monthly subscriptions are final.</p>
        </Paper >
    );
}