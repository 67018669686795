import { Avatar, Button, Card, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { _fetch } from "../../netReq";
import { useMixpanel } from 'react-mixpanel-browser';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { currentUser, loadAccount, loadGoogleAccounts, setLocations } from "../../redux/action/action";
import { useCookies } from "react-cookie";
import { Rating } from "@material-ui/lab";
import LineIconLight from "../../react-lineicons-light";
import LineIconRegular from "../../react-lineicons-regular";

const gradients = [
    'linear-gradient(45deg, #6e5acb 30%, #d553ab 90%)',
    'linear-gradient(45deg, #11998e 30%, #38ef7d 90%)',
    'linear-gradient(45deg, #e0814d 30%, #dfc560 90%)',
    'linear-gradient(45deg, #5B86E5 30%, #36D1DC 90%)'
]

export default function ManageListings() {
    const history = useHistory();
    const { account_id, account } = useSelector(st => st.userReducer);

    const [loadingLocations, setLoadingLocations] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const mixpanel = useMixpanel();

    const [subscription, setSubscription] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [locations, _setLocations] = useState([]);

    const dispatch = useDispatch();
    const [, setCookie, removeCookie] = useCookies(['account_id']);

    async function removeListing() {
        try {
            mixpanel.track('remove listing');
            setLoading(true);
            init();
            let { success } = await _fetch(`${process.env.REACT_APP_API_URL}/location`, {
                method: 'DELETE',
                body: { id: locations[selectedLocation]._id }
            });
            if (success) {
                let result = await loadAccount(dispatch);
                let users = result.response.users;
                if (Array.isArray(users) && users.length > 0) {
                    let current_user = users.find(e => e.account_id === account_id);
                    if (!current_user) {
                        setCookie('account_id', users[0].account_id, { maxAge: 30 * 24 * 60 * 60 });
                    } else {
                        await loadGoogleAccounts(dispatch, account_id, current_user);
                    }
                } else {
                    removeCookie('account_id');
                    dispatch(currentUser({}));
                    dispatch(setLocations({ locations: [] }));
                }
                toast.success('Listing removed successfully');
            }
            else toast.error('Some error occurred while removing listing please try again later.');
        } catch (e) {
            console.log(e);
            toast.error('Some error occurred while removing listing please try again later.');
        } finally {
            setLoading(false);
            setShowDialog(false);
        }
    }

    const getSubscription = useCallback(async () => {
        try {
            if (account?.subscription_status !== 'ACTIVE') return;
            let { response, success } = await _fetch(`${process.env.REACT_APP_API_URL}/payment/subscription`);
            if (success) {
                setSubscription(response);
            }
        } catch (err) {
            console.log(err);
        }
    }, [account?.subscription_status]);

    async function init() {
        try {
            setLoadingLocations(true);
            let { response, success } = await _fetch(`${process.env.REACT_APP_API_URL}/location/all`);
            if (success) {
                _setLocations(response);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingLocations(false);
        }
    }

    useEffect(() => {
        getSubscription();
        init();
    }, [getSubscription])

    if (loadingLocations) {
        return (
            <Paper className='min-h-100 d-flex' elevation={0}>
                <CircularProgress size={75} />
            </Paper>
        )
    }

    return (
        <div className='d-flex flex-column h-100 p-3'>

            <Grid container className='d-flex flex-wrap' spacing={3} alignItems='stretch'>
                {
                    Array.isArray(locations) && locations.length > 0 ?
                        locations.map((e, i) => (
                            <Grid item key={i} xs={12} md={4}>
                                <Card variant='elevation' elevation={5} className='h-100'>
                                    <CardHeader
                                        style={{ backgroundImage: gradients[i % 4], color: '#fff' }}
                                        avatar={
                                            e.listing_data?.profile
                                                ?
                                                <Avatar src={e.listing_data.profile}>
                                                    {e.locationName.substr(0, 1)}
                                                </Avatar>
                                                :
                                                null
                                        }
                                        title={<Typography variant='h6'>{e.locationName}</Typography>}
                                        subheader={
                                            <Rating
                                                icon={<LineIconLight name='star-fill' style={{ color: 'gold' }} size='sm' />}
                                                emptyIcon={<LineIconLight name='star-fill' style={{ color: 'lightgrey' }} size='sm' />}
                                                value={e.stats?.averageRating} readOnly
                                            />
                                        }
                                        action={
                                            <IconButton
                                                onClick={() => {
                                                    setSelectedLocation(i);
                                                    setShowDialog(true);
                                                }}
                                            >
                                                <LineIconLight name='trash-can-alt-2'
                                                    style={{ color: 'white' }} />
                                            </IconButton>
                                        }
                                    />
                                    <CardContent style={{ backgroundColor: '#fff' }}>
                                        <Typography variant='body1'>
                                            {/* <Grid container>
                                                <Grid xs={1}> */}
                                            <LineIconRegular name='briefcase' size='xs' className='mr-2' />
                                            {/* </Grid>
                                                <Grid> */}
                                            {e.locationName}
                                            {/* </Grid>
                                            </Grid> */}
                                        </Typography>
                                        {
                                            e.listing_data?.primary_phone &&
                                            <Typography variant='body1' className='mt-3'>
                                                {/* <Grid container>
                                                    <Grid xs={1}> */}
                                                <LineIconRegular name='phone' size='xs' className='mr-2' />
                                                {/* </Grid>
                                                    <Grid> */}
                                                {e.listing_data.primary_phone}
                                                {/* </Grid>
                                                </Grid> */}
                                            </Typography>
                                        }
                                        {
                                            e.listing_data?.address &&
                                            <Typography variant='body1' className='mt-3'>
                                                {/* <Grid container>
                                                    <Grid item xs={1}> */}
                                                <LineIconRegular name='map-marker' size='xs' className='mr-2' />
                                                {/* </Grid>
                                                    <Grid item xs={11}> */}
                                                {e?.listing_data?.address?.addressLines?.join(', ')} {e?.listing_data?.address?.administrativeArea} {e?.listing_data?.address?.postalCode}
                                                {/* </Grid>
                                                </Grid> */}
                                            </Typography>
                                        }
                                        {
                                            e.listing_data?.category &&
                                            <Typography variant='body1' className='mt-3'>
                                                {/* <Grid container>
                                                    <Grid xs={1}> */}
                                                <LineIconRegular name='layers-alt' size='xs' className='mr-2' />
                                                {/* </Grid>
                                                    <Grid xs={11}> */}
                                                {e.listing_data.category}
                                                {/* </Grid>
                                                </Grid> */}
                                            </Typography>
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))
                        :
                        <p>You have not added any GMB listings. <Link to='/login'>Add Listing</Link></p>
                }
            </Grid>

            <div className='mt-auto'>
                <Button
                    variant='contained' color='primary'
                    onClick={() => {
                        history.push('/login')
                        mixpanel.track('button click', { referer: 'manage_listing_bottom_button', action: 'navigate_to_add_listing' });
                    }}>
                    Manage More Listings
                </Button>
            </div>

            <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
                <DialogContent>
                    <Typography variant='body1' className='mb-2'>
                        Are you sure you want to remove this listing ? {subscription ? 'Removing this listing will change your subscription.' : ''}
                    </Typography>
                    {
                        subscription &&
                        <React.Fragment>
                            <div className='mb-3'>Your updated subscription will be</div>
                            <Grid container>
                                <Grid item xs={8} md={6}>
                                    Total subscribed slots
                                </Grid>
                                <Grid item xs={4} md={6}>{subscription.items.data[0].quantity - 1}</Grid>
                                <Grid item xs={8} md={6}>
                                    Monthly Recharge Value
                                </Grid>
                                <Grid item xs={4} md={6}>₹{(subscription.items.data.reduce((prev, c) => prev + (Math.max(c.quantity - 1, 0) * c.plan.amount), 0)) / 100}</Grid>
                            </Grid>

                            <Typography variant='caption' className='my-2'>
                                Note: JeeMB subscription is prepaid. No refund will be issued if you remove listing midway.
                            </Typography>

                        </React.Fragment>
                    }

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setShowDialog(false)}
                        variant='contained' color='primary'
                        disabled={loading} size='small'
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={removeListing}
                        disabled={loading}
                        startIcon={loading ? <CircularProgress size={20} /> : null}
                        variant='contained' color='primary' size='small'
                    >
                        Remove Listing
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}