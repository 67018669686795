import React from 'react';
import { Hidden, Typography } from '@material-ui/core';

import './styles.css';

export default function AutoReviewReply() {

    return (
        <div className='d-flex flex-column article-container'>
            <h3 className='mb-3 article-heading'>
                What is Review Tab and its short link and QR code
            </h3>
            <Typography variant='subtitle2' className='mb-4'>
                Last updated: September 23, 2021
            </Typography>

            <Hidden smUp>
                <iframe
                    className='mx-auto' width="320" height="180" src='https://www.youtube.com/embed/PHx5T2HDo6g'
                    title="YouTube video player" frameborder="0" allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
            </Hidden>
            <Hidden only={['xs', 'md', 'lg', 'xl']}>
                <iframe
                    className='mx-auto' width="560" height="315" src='https://www.youtube.com/embed/PHx5T2HDo6g'
                    title="YouTube video player" frameborder="0" allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
            </Hidden>
            <Hidden only={['xs', 'sm']}>
                <iframe
                    className='mx-auto' width="720" height="405" src='https://www.youtube.com/embed/PHx5T2HDo6g'
                    title="YouTube video player" frameborder="0" allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
            </Hidden>

            <p className='article-content'>
                JeeMB helps in simplifying your work and improve your SEO ranking. For every linked listing, JeeMB generates a Unique short link and unique QR code. After logging in, you can see the JeeMB link present in the mid-screen. By using this link, you can collect a lot of reviews for your listing.
            </p>

            <ol>
                <li className='article-content'>
                    Start with signing in to your JeeMB account. From the dashboard, locate the link available right next to your listing.
                    <img src={`${process.env.REACT_APP_AWS_BUCKET}img/jeemb-qr-link.png`} alt='...' className='article-image img img-fluid' />
                </li>
                <li className='article-content'>
                    To copy the link, use the copy button placed right to the link. The link will be copied to the clipboard. You will use this link on different platforms such as e-mails, messages, groups, chats, etc.
                    <img src={`${process.env.REACT_APP_AWS_BUCKET}img/copy-url-5.1.png`} alt='...' className='article-image img img-fluid' />
                </li>
                <li className='article-content'>
                    Alternatively, you can even use the QR graphic, you can use offline by pasting them in your space, shop, office, or wherever your visitors get to see it. They can scan the QR code and share their valuable review.
                </li>
                <li className='article-content'>
                    Click on the download button placed right next to the link. The code will be directly downloaded to your system. You can also share the graphic directly with Facebook, WhatsApp, or Twitter.
                    <img src={`${process.env.REACT_APP_AWS_BUCKET}img/qrcode-3.2.png`} alt='...' className='article-image img img-fluid' />
                </li>
            </ol>

        </div>
    );

}