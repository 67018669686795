import { Badge, Button, Card, Typography } from '@material-ui/core'
import React from 'react';


export function InfoView({ mask, old, handleComponent, update = null }) {
    let oldInfo = old.path ? old.dat[old.path] : old.dat
    let updateInfo = null;
    if (update)
        updateInfo = update.path ? update.dat[update.path] : update.dat;
    // function editSwitch(){
    //     switch(mask){
    //         case 'regularHours':

    //     }
    // }
    return (
        <React.Fragment>
            <Card className={`mx-auto my-2 ${update ? 'bg-dark' : ''}`}
                // onClick={() => edit ? setEdit(false) : setEdit(true)}
                // style={{ cursor: 'pointer' }}
                variant='outlined'
            >
                <div className='d-flex ml-3 pt-3'>
                    <Badge
                        color='primary'
                        variant='dot'
                        overlap='circle'
                        invisible={!update}
                        className='my-auto mx-2'
                    >
                        <old.Icon size={25} color='#6e5acb' />
                    </Badge>
                    <Typography variant='h5' color='primary'>
                        {old.name}
                    </Typography>
                    {/* <FiEdit2 size={22} className='ml-auto mr-3' /> */}
                </div>
                {/* <div className='d-flex'>
                    <Typography className='w-50 ml-3 px-3 my-3'>
                        Current Infomation
                        </Typography>
                    <Typography className = 'w-50 ml-3 px-3 my-3'>
                        Suggestion
                        </Typography>
                </div> */}
                <div className='d-flex'>
                    <div className={`w-${update ? '50' : '100'} ml-3 p-3`}>
                        {
                            // edit
                            //     ?
                            //     <div className='d-flex'>
                            //         <TextField label="Edit" variant='outlined' defaultValue={oldInfo}
                            //             className='w-50'

                            //         />
                            //         <Button className='ml-3' color='primary'>
                            //             Update
                            //         </Button>
                            //         <Button className='ml-3' color='secondary'>
                            //             Cancel
                            //         </Button>
                            //     </div>
                            //     :
                            oldInfo
                                ?
                                Array.isArray(oldInfo)
                                    ?
                                    oldInfo.map((e, i) => (
                                        <Typography key={i}>
                                            {e.openDay} : {e.openTime} - {e.closeTime}
                                        </Typography>
                                    ))
                                    :
                                    typeof oldInfo === 'object'
                                        ?
                                        Object.keys(oldInfo).map((key, i) => (
                                            <Typography key={i}>{key} : {oldInfo[key]}</Typography>
                                        ))
                                        :
                                        <Typography>{oldInfo}</Typography>
                                :
                                <Typography>No Info</Typography>
                        }
                        {
                            update
                                ?
                                <Button variant='outlined' color='primary'
                                    className='my-2'
                                    onClick={(event) => handleComponent({
                                        nm: mask,
                                        dat: old.dat
                                    })}
                                >
                                    Keep Current
                                </Button>
                                :
                                null
                        }
                    </div>
                    {
                        update
                            ?
                            <div className='w-50 ml-3 p-3'>
                                {
                                    Array.isArray(updateInfo)
                                        ?
                                        updateInfo.map((e, i) => (
                                            <Typography key={i}>
                                                {e.openDay} : {e.openTime} - {e.closeTime}
                                            </Typography>
                                        ))
                                        :
                                        typeof updateInfo === 'object'
                                            ?
                                            Object.keys(updateInfo).map((key, i) => (
                                                <Typography key={i}>{key} : {updateInfo[key]}</Typography>
                                            ))
                                            :
                                            <Typography>{updateInfo}</Typography>
                                }
                                <Button variant='outlined' color='primary' className='my-2'
                                    onClick={() => handleComponent({
                                        nm: mask,
                                        dat: update.dat
                                    })}
                                >
                                    Accept Update
                            </Button>
                            </div>
                            :
                            null
                    }
                </div>
            </Card>
        </React.Fragment >
    )
}