import Cookies from 'universal-cookie';
const cookies = new Cookies();

/**
 * 
 * @param {String} url url of the api / service 
 * @param {Object} obj options to pass method, body, token for the request 
 * @param {('GET'|'POST'|'PATCH'|'DELETE')} obj.method http method to use for request
 * @param {Object} obj.body http message body if request type is POST or PATCH
 * @param {String} obj.token OPTIONAL, token to send in Authorization header if required
 * @param {String} account_id OPTIONAL, account id od the google account, used if account id in cookie is null
 * @returns 
 */
export const _fetch = async (url, obj = {}) => {

    let headers = {};
    let { method = 'GET', token = null, body = undefined, account_id = '' } = obj;

    if (body)
        headers['Content-Type'] = 'application/json';
    if (token)
        headers.Authorization = `Bearer ${token}`;

    let options = {
        headers,
        body: JSON.stringify(body),
        method,
    }

    if (!token) {
        options.credentials = 'include';
        if(cookies.get('account_id')){
            headers.accountid = cookies.get('account_id');
        } else if (account_id) {
            headers.accountid = account_id;
        }
    }

    try {
        let res = await fetch(url, options)
        if (res.status === 401) {
            cookies.remove('account_id');
            window.location = '/';
            return;
        } else if (res.status === 402) {
            cookies.remove('account_id');
            window.location = '/buy_slots';
            return;    
        }
        res = await res.json()
        return res
    } catch (e) {
        process.env.NODE_ENV === 'development' && console.log(e)
        throw (e)
    }
    // .then(res => res.json())
}
// export const getLocId = (locName) => {
//     locId.push(locName[i].split("/")[3]);
//     log.info(locId);
//     return locId;
// }